import { LoadState } from "../../constants/enums";
import { IDrawing, IDrawingState } from "./drawing.types";


export const initialDrawing: IDrawing = {
  drawing_uuid: "",
  drawing_no: "",
  bom_no: null,
  prepared_by_uuid: null,
  prepared_by_name: null,
  checked_by_uuid: null,
  checked_by_name: null,
  approved_by_uuid: null,
  approved_by_name: null,
  drawing_link: "",
  approval_status: null,
  status: "ACTIVE",
};


export const initialDrawingState: IDrawingState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  drawing: {
    data: initialDrawing,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
