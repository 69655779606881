import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";

const EnquiriesTable = Loadable(
  lazy(() =>
    import("./EnquiriesTable").then(({ EnquiriesTable }) => ({
      default: EnquiriesTable,
    })),
  ),
);
const ManageSingleProject = Loadable(
  lazy(() =>
    import("./ManageSingleProject").then(({ ManageSingleProject }) => ({
      default: ManageSingleProject,
    })),
  ),
);
const EnquiryDetailsWithTabs = Loadable(
  lazy(() =>
    import("./EnquiryDetailsWithTabs").then(({ EnquiryDetailsWithTabs }) => ({
      default: EnquiryDetailsWithTabs,
    })),
  ),
);
const EnquiryActivityAndEmails = Loadable(
  lazy(() =>
    import("./EnquiryActivityAndEmails").then(
      ({ EnquiryActivityAndEmails }) => ({
        default: EnquiryActivityAndEmails,
      }),
    ),
  ),
);


export const enquiryRoutes = [
  {
    path: "/sales/enquiries",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <EnquiriesTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/sales/enquiries/manage",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <ManageSingleProject />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/sales/enquiries/manage/:enquiryNumber/:uuid",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <EnquiryDetailsWithTabs />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/sales/enquiries/manage/:enquiryNumber/:uuid/:tab",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <EnquiryDetailsWithTabs />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/sales/enquiries/activity&emails/:enquiryNumber/:uuid",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <EnquiryActivityAndEmails />
      </AuthorizedRoute>
    ),
  },

  {
    path: "/sales/orders",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ORDER]}>
        <EnquiriesTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/sales/orders/manage/:enquiryNumber/:uuid/:order_uuid",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ORDER]}>
        <EnquiryDetailsWithTabs />
      </AuthorizedRoute>
    ),
  }  
];
