import { LoadState } from "../../../constants/enums";
import {
  IProductTemplate,
  IProductTemplateState,
} from "./product-templates.types";

export const defaultProductTemplate: IProductTemplate = {
  product_template_uuid: null,
  template_name: "",
  product_items: [""],
  status: "ACTIVE",
  created_by_uuid: null,
};

export const defaultProductTemplateState: IProductTemplateState = {
  templates: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_template: {
    data: defaultProductTemplate,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
