import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { IEnquiryMockupRequestForm } from "./enquiry-mrf.types";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";

export const FETCH_SINGLE_ENQUIRY_MRF_PROGRESS =
  "FETCH_SINGLE_ENQUIRY_MRF_PROGRESS";
export const FETCH_SINGLE_ENQUIRY_MRF_SUCCESS =
  "FETCH_SINGLE_ENQUIRY_MRF_SUCCESS";
export const FETCH_SINGLE_ENQUIRY_MRF_FAILED =
  "FETCH_SINGLE_ENQUIRY_MRF_FAILED";

export const fetchSingleEnquiryMrfProgress = () =>
  action(FETCH_SINGLE_ENQUIRY_MRF_PROGRESS);
export const fetchSingleEnquiryMrfSuccess = (data: IEnquiryMockupRequestForm) =>
  action(FETCH_SINGLE_ENQUIRY_MRF_SUCCESS, { data });
export const fetchSingleEnquiryMrfFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_ENQUIRY_MRF_FAILED, { errorMessage });

export const fetchSingleEnquiryMrfAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleEnquiryMrfProgress());
      const res = await api.get(`/enquiry/get-mrf?mrf_uuid=${uuid}`);
      const data: IEnquiryMockupRequestForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleEnquiryMrfSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleEnquiryMrfFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleEnquiryMrfFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchSingleEnquiryMrfByEnquiryNoAsync =
  (enquiry_no: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleEnquiryMrfProgress());
      const res = await api.get(`/enquiry/get-mrf?enquiry_no=${enquiry_no}`);
      const data: IEnquiryMockupRequestForm[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleEnquiryMrfSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleEnquiryMrfFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleEnquiryMrfFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
export const upsertMockupRequestFormAsync =
  (
    qrf_data: IEnquiryMockupRequestForm,
    onCallback: (
      isSuccess: boolean,
      customer?: IEnquiryMockupRequestForm,
    ) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = qrf_data;
      dispatch(saveLoaderProgress());
      const res = await api.post("/enquiry/upsert-mrf", payload);
      let message = "MRF saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_ENQUIRY_MRF = "CLEAR_SINGLE_ENQUIRY_MRF";
export const clearSingleEnquiryMrf = () => action(CLEAR_SINGLE_ENQUIRY_MRF);
