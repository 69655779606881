import { LoadState } from "../../../constants/enums";
import { IApprovalItem, IApprovalItemState } from "./productExpiry.types";

export const defaultApprovalQuote: IApprovalItem = {
  product_id: "",
  product_uuid: "",
  product_name: "",
  costing_sheet_for: null,
  creation_costing_sheet_uuid: null,
  layout_location: null,
  finishes: null,
  fabric_colour: null,
  metal: null,
  wood: null,
  glass: null,
  acrylic: null,
  polycarbonate: null,
  product_code_prefix: "",
  product_code_suffix: "",
  product_type: null,
  product_description: null,
  eni_no: null,
  product_height: null,
  product_depth: null,
  product_width: null,
  quantity: null,
  product_price: null,
  total: null,
  weight: null,
  total_weight: null,
  volume_m3: null,
  total_volume_m3: null,
  category: null,
  design: "",
  expiry_date: "",
  status: "",
  created_by_uuid: "",
  product_code: "",
  approval_uuid: "",
  requested_by_uuid: "",
  approval_status: "",
};

export const initialApprovalItemState: IApprovalItemState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
};
