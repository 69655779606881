import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { ITaskBaord, initialTaskBoardState } from "./taskBoard.types";
import { TaskBoardActions } from ".";
import {
  FETCH_TASKBOARD_FAILED,
  FETCH_TASKBOARD_PROGRESS,
  FETCH_TASKBOARD_SUCCESS,
  FETCH_TASK_LIST_FAILED,
  FETCH_TASK_LIST_PROGRESS,
  FETCH_TASK_LIST_SUCCESS,
  UPSERT_TASKBOARD_SUCCESS,
} from "./taskBoardActions";

export const tasksBoardReducer = (
  state: IStoreState["taskBoard"] = initialTaskBoardState,
  action: TaskBoardActions,
) => {
  switch (action.type) {
    case FETCH_TASK_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.InProgress;
        draftState.list = initialTaskBoardState["list"];
      });
      return newState;
    }
    case FETCH_TASK_LIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.listLoading = LoadState.Loaded;
        draftState.list = data;
      });
      return newState;
    }
    case FETCH_TASK_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_TASKBOARD_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.data = initialTaskBoardState["data"];
      });
      return newState;
    }
    case FETCH_TASKBOARD_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.data = data;
      });
      return newState;
    }
    case FETCH_TASKBOARD_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
      });
      return newState;
    }
    case UPSERT_TASKBOARD_SUCCESS: {
      const { data, mode, status } = action.payload;
      const newState = produce(state, (draftState) => {
        let taskBoardData = { ...state.data };
        const statusKey = status ? status : data.status;
        console.log("statusKey and mode =>", statusKey, mode);
        if (mode === "new") {
          const checkKeyExist = taskBoardData[statusKey as "TODO"];
          if (checkKeyExist) {
            taskBoardData[statusKey as "TODO"] = [
              data,
              ...taskBoardData[statusKey as "TODO"],
            ];
          } else {
            taskBoardData = {
              ...taskBoardData,
              [statusKey]: [data],
            };
          }
        }
        if (mode === "delete") {
          const filteredData = taskBoardData[statusKey as "TODO"].filter(
            (item) => item.task_uuid !== data.task_uuid,
          );

          taskBoardData = {
            ...taskBoardData,
            [statusKey as "TODO"]: filteredData,
          };
          // if (deleteIndex) {
          //   let dataTODelete = [...taskBoardData[statusKey as "TODO"]];
          //   console.log("delete taskBoardData[statusKey] =>", dataTODelete)
          //   console.log("delete deleteIndex =>", deleteIndex)

          //   dataTODelete.splice(deleteIndex, 1)
          //   console.log("delete after delete =>", dataTODelete)

          //   console.log("delete taskBoardData =>", taskBoardData)
          // }
        }
        if (mode === "update") {
          const updateIndex = taskBoardData[statusKey as "TODO"].findIndex(
            (item) => item.task_uuid === data.task_uuid,
          );
          if (updateIndex) {
            taskBoardData[statusKey as "TODO"].splice(updateIndex, 1, data);
          }
        }
        draftState.loading = LoadState.Loaded;
        draftState.data = taskBoardData;
      });
      console.log("new delete state ==>", newState);
      return newState;
    }
    default: {
      return state;
    }
  }
};
