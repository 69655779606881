import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../../api/api";
import { IFileUpload } from "../../../components/FileUpload/FileUpload.type";
import { IStoreState } from "../../initialStoreState";
import { showMessage } from "../../messages/messagesActions";
import { IProject, IUpsertProject } from "./project.types";

export const FETCH_PROJECT_PROGRESS = "FETCH_PROJECT_PROGRESS";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECT_FAILED = "FETCH_PROJECT_FAILED";

export const fetchProjectProgress = () => action(FETCH_PROJECT_PROGRESS);
export const fetchProjectSuccess = (project: IProject) =>
  action(FETCH_PROJECT_SUCCESS, { project });
export const fetchProjectFailed = (errorMessage: string) =>
  action(FETCH_PROJECT_FAILED, { errorMessage });

export const fetchProjectAsync =
  (projectId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchProjectProgress());

      const res = await api.get(
        `/enquiry/get-project?project_uuid=${projectId}`,
      ); // need to be replaced
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchProjectSuccess(data[0]));
      } else {
        dispatch(
          fetchProjectFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchProjectFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertProjectAsync =
  (
    project: IProject,
    onCallback: (
      isSuccess: boolean,
      enquiryUUID?: string,
      enquiryNumber?: string,
    ) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const payload: IUpsertProject = {
        project_uuid: project.project_uuid,
        project_name: project.project_name,
        originated_by: project.originated_by,
        employee_or_dealer_uuid: project.employee_or_dealer_uuid,
        employee_or_dealer_name: project.employee_or_dealer_name,
        dealer_name: project.dealer_name,
        dealer_uuid: project.dealer_uuid,

        architect_firm_uuid: project.architect_firm_uuid,
        architect_firm_name: project.architect_firm_name,
        architect_name_uuid: project.architect_name_uuid,
        architect_name_name: project.architect_name_name,

        pmc_firm_uuid: project.pmc_firm_uuid,
        pmc_firm_name: project.pmc_firm_name,
        pmc_name_uuid: project.pmc_name_uuid,
        pmc_name_name: project.pmc_name_name,

        time_frame_of_closing: project.time_frame_of_closing,
        sales_location: project.sales_location,
        sales_invoice_zone: project.sales_invoice_zone,
        delivery_address_line1: project.delivery_address_line1,
        delivery_address_line2: project.delivery_address_line2,
        delivery_address_city: project.delivery_address_city,
        delivery_address_state: project.delivery_address_state,
        delivery_address_country: project.delivery_address_country,
        delivery_address_pincode: project.delivery_address_pincode,
        project_status: project.project_status,
        type: project.type,
      };

      // const orgiinalState = getState().leads.project.data
      // const changeStstaus = compareProjectStates(orgiinalState, payload, files)

      // if (files.length > 0) {
      //   const asPayload = {
      //     project_name: project.project_name,
      //   };
      //   const result = await uploadMultipleFiles(files, "PROJECT", asPayload);
      //   payload.attachments = Object.assign({}, result);
      // }

      const result = await api.post("/enquiry/upsert-project", payload); // need to be replaced
      const enquiry_uuid = result.data?.data?.enquiry_uuid;
      const enquiry_no = result.data?.data?.enquiry_no;
      let message = "Project is saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        }),
      );
      onCallback(true, enquiry_uuid, enquiry_no);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_PROJECT = "CLEAR_PROJECT";

export const clearProject = () => action(CLEAR_PROJECT);

//=============================== Helper Functions ===============================

export const compareProjectStates = (
  orginalState: IProject,
  draftState: IUpsertProject,
  files: IFileUpload[],
): boolean => {
  const { project_id, insert_ts, create_ts, created_by_uuid, ...original } =
    orginalState;
  const projectChange = JSON.stringify(original) !== JSON.stringify(draftState);

  let fileChange = false;
  if (files.length > 0) {
    for (const file of files) {
      if (file.file) {
        fileChange = true;
      }
    }
  }
  return projectChange || fileChange;
};

export const convertObjectKeysToFileFormat = (files: any): IFileUpload[] => {
  return Object.keys(files || {}).map((key) => {
    // let fileName: string[] | string = files[key].split('/') as string[]
    // fileName = fileName[fileName.length - 1]
    return {
      key: key,
      file: null,
      path: files[key],
      // name: fileName
    };
  });
};
