import produce from "immer";

import {
  FETCH_SINGLE_WORK_ORDER_FAILED,
  FETCH_WORK_ORDER_LIST_FAILED,
  FETCH_WORK_ORDER_LIST_PROGRESS,
  FETCH_WORK_ORDER_LIST_SUCCESS,
  FETCH_SINGLE_WORK_ORDER_PROGRESS,
  FETCH_SINGLE_WORK_ORDER_SUCCESS,
  CLEAR_SINGLE_WORK_ORDER,
  CLEAR_WORK_ORDER_LIST,
  CLEAR_WORK_ORDER_STATE,
} from "./workOrder.actions";
import {
  FETCH_SINGLE_WORK_ORDER_JOB_FAILED,
  FETCH_WORK_ORDER_JOB_LIST_FAILED,
  FETCH_WORK_ORDER_JOB_LIST_PROGRESS,
  FETCH_WORK_ORDER_JOB_LIST_SUCCESS,
  FETCH_SINGLE_WORK_ORDER_JOB_PROGRESS,
  FETCH_SINGLE_WORK_ORDER_JOB_SUCCESS,
  CLEAR_SINGLE_WORK_ORDER_JOB,
  CLEAR_WORK_ORDER_JOB_LIST,
} from "./workOrderJob.actions";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultWorkOrderState } from "./workOrder.state";
import { WorkOrderActions } from "./index";

export const workOrderReducer = (
  state: IStoreState["workOrder"] = defaultWorkOrderState,
  action: WorkOrderActions,
) => {
  switch (action.type) {
    // ###########################################################################
    // ########################### Work Order Reducer ############################
    // ###########################################################################
    case FETCH_WORK_ORDER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.workOrderList.loading = LoadState.InProgress;
        draftState.workOrderList.data = [];
        draftState.workOrderList.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_WORK_ORDER_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.workOrderList.loading = LoadState.Loaded;
        draftState.workOrderList.data = data;
        draftState.workOrderList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_WORK_ORDER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.workOrderList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_SINGLE_WORK_ORDER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.singleWorkOrder.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_WORK_ORDER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singleWorkOrder.loading = LoadState.Loaded;
        draftState.singleWorkOrder.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_WORK_ORDER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singleWorkOrder.loading = LoadState.Failed;
        draftState.singleWorkOrder.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_SINGLE_WORK_ORDER: {
      const newState = produce(state, (draftState) => {
        draftState.singleWorkOrder.loading = LoadState.NotLoaded;
        draftState.singleWorkOrder.data =
          defaultWorkOrderState["singleWorkOrder"]["data"];
        draftState.singleWorkOrder.error = null;
      });
      return newState;
    }

    case CLEAR_WORK_ORDER_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.workOrderList.loading = LoadState.NotLoaded;
        draftState.workOrderList.data =
          defaultWorkOrderState["workOrderList"]["data"];
        draftState.workOrderList.error = null;
      });
      return newState;
    }
    case CLEAR_WORK_ORDER_STATE: {
      return defaultWorkOrderState;
    }

    // ###########################################################################
    // ######################### Work Order Job Reducer ##########################
    // ###########################################################################
    case FETCH_WORK_ORDER_JOB_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.workOrderJobList.loading = LoadState.InProgress;
        draftState.workOrderJobList.data = [];
        draftState.workOrderJobList.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_WORK_ORDER_JOB_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.workOrderJobList.loading = LoadState.Loaded;
        draftState.workOrderJobList.data = data;
        draftState.workOrderJobList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_WORK_ORDER_JOB_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.workOrderJobList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_SINGLE_WORK_ORDER_JOB_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.singleWorkOrderJob.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_WORK_ORDER_JOB_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singleWorkOrderJob.loading = LoadState.Loaded;
        draftState.singleWorkOrderJob.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_WORK_ORDER_JOB_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singleWorkOrderJob.loading = LoadState.Failed;
        draftState.singleWorkOrderJob.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_SINGLE_WORK_ORDER_JOB: {
      const newState = produce(state, (draftState) => {
        draftState.singleWorkOrderJob.loading = LoadState.NotLoaded;
        draftState.singleWorkOrderJob.data =
          defaultWorkOrderState["singleWorkOrderJob"]["data"];
        draftState.singleWorkOrderJob.error = null;
      });
      return newState;
    }

    case CLEAR_WORK_ORDER_JOB_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.workOrderJobList.loading = LoadState.NotLoaded;
        draftState.workOrderJobList.data =
          defaultWorkOrderState["workOrderJobList"]["data"];
        draftState.workOrderJobList.error = null;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
