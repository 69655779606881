import { LoadState } from "../../constants/enums";
import { IWorkOrder, IWorkOrderJob, IWorkOrderState } from "./workOrder.types";

export const defaultWorkOrder: IWorkOrder = {
  work_order_uuid: null,
  batch_no: "",
  doc_no: "",
  rev_no: null,
  job_no: null,
  current_station: null,
  start_date: null,
  finish_date: null,
  planning_finish_date: null,
  printed_on_date: null,
  printed_by_uuid: null,
  printed_by_name: null,
  status: "ACTIVE",
};

export const defaultWorkOrderJob: IWorkOrderJob = {
  work_order_job_uuid: null,
  S: "ZZ",
  job_type: "WOOD",
  current_station: null,
  code: null,
  product_name: null,
  colour: null,
  to: null,
  quantity: null,
  time: null,
  routing: null,
  cut_size: null,
  edging: null,
  remark: null,
  description: null,
  prog: null,
  size_tools: null,
  reference: null,
  status: "ACTIVE",
};

export const defaultWorkOrderState: IWorkOrderState = {
  workOrderList: {
    data: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
    error: null,
  },
  singleWorkOrder: {
    data: defaultWorkOrder,
    loading: LoadState.NotLoaded,
    error: null,
  },
  workOrderJobList: {
    data: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
    error: null,
  },
  singleWorkOrderJob: {
    data: defaultWorkOrderJob,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
