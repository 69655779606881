import { Box, MenuItem, Stack, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StatusRenderer } from "../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { StandardCard } from "../../../components/Cards";
import { CustomLink } from "../../../components/CustomLink/CustomLink";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { DataTable } from "../../../components/Table/DataTable";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../../components/Table/plugins";
import { PageContainer } from "../../../components/container/PageContainer";
import { CustomSelect } from "../../../components/formsComponents";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { IconicMultiColumnCard } from "../../../components/iconicMultiColumnCard/IconicMultiColumnCard";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import { capitalizeWords, renderDropdownValue } from "../../../helpers";
import { useDispatchWrapper, useRecordCounts } from "../../../hooks";
import { Breadcrumb } from "../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { IUser } from "../../../redux/UserProfileList/userProfile.types";
import { fetchUsersListAsync } from "../../../redux/UserProfileList/userProfileActions";
import { IStoreState } from "../../../redux/initialStoreState";
import { RoleBasedCustomButton } from "../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { AddNewUserDialog } from "./AddNewUser";
import { BeforeProfileTypes } from "./UsersList.types";

export const BeforeProfile: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "users",
    },
  ];

  const { data, totalNumberOfRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.userprofile.users,
  );
  const [openCreateUser, setOpenCreateUser] = React.useState(false);

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 50,
  });
  const [selectedOpp, setSelectedOppr] = React.useState<{
    isAllSelected: boolean;
    leads: BeforeProfileTypes[];
  }>({
    isAllSelected: false,
    leads: [],
  });
  const [status, setStatus] = React.useState<
    BeforeProfileTypes["status"] | "-1"
  >("-1");

  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "last28Days" });

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const [searchType, setSearchType] = React.useState<string[]>([]);
  const [search, setSearch] = React.useState<string>("");

  const fetchList = async () => {
    dispatch(
      fetchUsersListAsync(
        tablePagination.pageNumber,
        tablePagination.rowsInPerPage,
        status,
        date,
        search,
        searchType,
      ),
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePagination, date, search, searchType]);

  const menuItems =
    selectedOpp.leads.length > 0 || selectedOpp.isAllSelected
      ? [
          {
            key: "action",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={""}
                displayEmpty
                renderValue={renderDropdownValue(`Choose Bulk Action`)}
                //onChange={(e) => setBranchOffice(e.target.value as string)}
              >
                <MenuItem value="1">Email</MenuItem>
              </CustomSelect>
            ),
          },
        ]
      : [];

  const usersTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalNumberOfRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    isDataLoading: loading === LoadState.InProgress,
    // selectionMode: "multiple",
    // uniqueRowKeyName: "user_uuid",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          ...menuItems,
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
              >
                <MenuItem value="-1">All Status</MenuItem>
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, fromDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, toDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <CSVExportPlugin
                columns={columns}
                items={items}
                filePrefixName="Report"
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {
          // searchField: {
          //   searchKeys: [],
          //   items: data,
          // },
        },
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchType}
                  dropdownOptions={[
                    { label: "Name", value: "first_name" },
                    { label: "Email", value: "personal_email" },
                    { label: "Mobile", value: "mobile" },
                    { label: "Department", value: "department" },
                    { label: "Branch", value: "branch_name" },
                  ]}
                  onDropdownChange={(value) => setSearchType(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "first_name",
        headerName: "Name",
        fieldName: "first_name",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IUser) => {
          return (
            <Stack direction="row" alignItems={"center"} spacing={1}>
              {value.value && (
                <>
                  <Avatar
                    sx={{
                      bgcolor:
                        value.column.rowIndex % 2 === 0
                          ? deepOrange[400]
                          : deepPurple[400],
                    }}
                  >
                    {value.value.substring(0, 1)}
                  </Avatar>
                  <CustomLink
                    to={"/users/manage/" + row.user_uuid}
                    // /users/manage/" + row.user_fact_id
                    label={row.first_name + " " + row.last_name}
                  />
                </>
              )}
            </Stack>
          );
        },
      },

      {
        key: "email",
        headerName: "Email",
        fieldName: "email",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "branch_name",
        headerName: "Branch",
        fieldName: "branch_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "role_name",
        headerName: "Role Name",
        fieldName: "role_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IUser) => {
          return <StatusRenderer status={row.status} />;
        },
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        headingAlign: "center",
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate("/users/manage/" + row.user_uuid);
              }}
            />
          );
        },
      },
    ],
    items: data,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
    onSelection: (isAllSelected: boolean, rows: BeforeProfileTypes[]) => {
      setSelectedOppr({ isAllSelected: isAllSelected, leads: rows });
    },
  };

  const handleToggleCreate = () => {
    setOpenCreateUser(!openCreateUser);
    // navigate("/users/manage");
  };

  const { list: recordsCounts, loading: recordCountsLoading } =
    useRecordCounts();

  // React.useEffect(() => {
  //   dispatch(
  //     fetchRecordCountsAsync(
  //       "latest_user",
  //       "status",
  //       date.fromDate,
  //       date.toDate,
  //     ),
  //   );
  //   return () => {
  //     dispatch(clearRecordCounts());
  //   };
  // }, [date.fromDate, date.toDate]);

  return (
    <PageContainer title="View Users" description="this is before profile">
      <Breadcrumb title="" items={BCrumb} />

      <IconicMultiColumnCard
        columns={recordsCounts.map((item) => {
          return {
            heading: capitalizeWords(item.status),
            value: item.count,
            colSize: 2,
          };
        })}
      />

      <StandardCard>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{
            mx: 5,
            my: 2,
          }}
        >
          <Typography variant="h2" fontWeight={"700"}>
            Users
          </Typography>
          <RoleBasedCustomButton
            moduleId={MODULE_IDS.USERS}
            variant="contained"
            onClick={handleToggleCreate}
          >
            Add User
          </RoleBasedCustomButton>
        </Box>

        <DataTable {...usersTableProps} />
      </StandardCard>
      {/* {openCreateUser && <Navigate to="/users/manage" replace={true} />} */}

      {openCreateUser && (
        <AddNewUserDialog open={openCreateUser} onClose={handleToggleCreate} />
      )}
    </PageContainer>
  );
};
