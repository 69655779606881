import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../../api/api";
import { IQueryParams } from "../../common/common.types";
import { getSearchQuery } from "../../common/helpers";
import { IStoreState } from "../../initialStoreState";
import {
  showMessage
} from "../../messages/messagesActions";
import { IApprovalItem } from "./productExpiry.types";

export const FETCH_APPROVAL_ITEM_LIST_PROGRESS =
  "FETCH_APPROVAL_ITEM_LIST_PROGRESS";
export const FETCH_APPROVAL_ITEM_LIST_SUCCESS =
  "FETCH_APPROVAL_ITEM_LIST_SUCCESS";
export const FETCH_APPROVAL_ITEM_LIST_FAILED =
  "FETCH_APPROVAL_ITEM_LIST_FAILED";

export const fetchApprovalItemListProgress = () =>
  action(FETCH_APPROVAL_ITEM_LIST_PROGRESS);
export const fetchApprovalItemListSuccess = (
  list: IApprovalItem[],
  totalRecords: number,
) => action(FETCH_APPROVAL_ITEM_LIST_SUCCESS, { list, totalRecords });
export const fetchApprovalItemListFailed = () =>
  action(FETCH_APPROVAL_ITEM_LIST_FAILED);

export const fetchApprovalItemListAsync =
  (
    queryParams: IQueryParams
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchApprovalItemListProgress());
        const searchQuery = getSearchQuery(queryParams);
        const res = await api.get(`/approval/get-approval?table_name=latest_product&status=REQUESTED&${searchQuery}`);
        const data: IApprovalItem[] = res.data.data;
        const totalRecords = res.data.totalRecords;

        dispatch(fetchApprovalItemListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchApprovalItemListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

// export const approvalAsync =
//   (
//     data: IHandleApproval,
//     onCallback: (isSuccess: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//     async (dispatch, getState) => {
//       try {
//         dispatch(saveLoaderProgress());
//         await api.post("/enquiry/approve-orf-update-table", data);
//         let message = "Action success!";
//         dispatch(
//           showMessage({
//             type: "success",
//             displayAs: "snackbar",
//             message: message,
//           }),
//         );
//         onCallback(true);
//       } catch (err: any) {
//         onCallback(false);
//         dispatch(
//           showMessage({
//             type: "error",
//             message: err.response.data.message,
//             displayAs: "snackbar",
//           }),
//         );
//       } finally {
//         dispatch(saveLoaderCompleted());
//       }
//     };

// export const handleApprovalAsync =
//   (
//     data: IHandleApproval,
//     onCallback: (isSuccess: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//     async (dispatch, getState) => {
//       try {
//         dispatch(saveLoaderProgress());
//         await api.post("/approval/handle-approval", data);
//         let message = "Action success!";
//         dispatch(
//           showMessage({
//             type: "success",
//             displayAs: "snackbar",
//             message: message,
//           }),
//         );
//         onCallback(true);
//       } catch (err: any) {
//         onCallback(true);
//         dispatch(
//           showMessage({
//             type: "error",
//             message: err.response.data.message,
//             displayAs: "snackbar",
//           }),
//         );
//       } finally {
//         dispatch(saveLoaderCompleted());
//       }
//     };

export const CLEAR_APPROVAL_ITEM_STATE = "CLEAR_APPROVAL_ITEM_STATE";
export const clearApprovalItemState = () => action(CLEAR_APPROVAL_ITEM_STATE);
