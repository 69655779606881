import { LoadState } from "../../constants/enums";

export interface ITaskBoardState {
  list: ITaskListItem[];
  totalRecords: number;
  listLoading: LoadState;
  data: ITaskBaord;
  loading: LoadState;
}


export interface ITaskBoardItem {
  id: number | string;
  task_uuid: string | null;
  type: "Quote" | "Enquiry";
  type_name: string | null;
  type_uuid: string | null;
  title: string;
  description: string | null;
  dueDate: string;
  uploadFile: JSON | null;
  priority: "HIGH" | "MEDIUM" | "LOW";
  assigned_to_name: string | null;
  assigned_to_uuid: string | null;
  status: "TODO" | "PROGRESS" | "HOLD" | "COMPLETED" | "ARCHIVE";
  create_ts?: string;
  insert_ts?: string;
}

export interface ITaskBaord {
  assigned_to_id: number | null;
  TODO: ITaskBoardItem[];
  PROGRESS: ITaskBoardItem[];
  HOLD: ITaskBoardItem[];
  COMPLETED: ITaskBoardItem[];
  priority: string | null;
  status: string;
  create_ts?: string;
  insert_ts?: string;
}
export interface ITaskListItem {
  task_uuid: string | null,
  type: "Project",
  type_name: string,
  type_uuid: string,
  project_manager: string | null,
  title: string,
  description: string | null,
  due_date: string | null,
  upload_file: string | null,
  priority: "HIGH" | "MEDIUM" | "LOW",
  category_name: string | null,
  category_uuid: string | null,
  assigned_to_name: string,
  assigned_to_uuid: string,
  time_taken: string | null,
  status: "TODO" | "PROGRESS" | "HOLD" | "COMPLETED" | "ARCHIVE"

  // read only
  create_ts?: string,
  insert_ts?: string,
  rowId?: string,
}

export const initialTaskBoardState: ITaskBoardState = {
  list: [],
  totalRecords: 0,
  listLoading: LoadState.NotLoaded,
  data: {
    assigned_to_id: 0,
    TODO: [],
    PROGRESS: [],
    HOLD: [],
    COMPLETED: [],
    priority: null,
    status: "ACTIVE",
  },
  loading: LoadState.NotLoaded,
};


