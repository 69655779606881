import { ISelectOption } from "../../constants/types";

export const COSTING_SHEET_ROUTE = "/costing-sheets";

export const costing_sheet_request_for: ISelectOption[] = [
  { label: "ORDER ENTRY", value: "ORDER ENTRY" },
  { label: "XD/RD", value: "XD/RD" },
  { label: "MOCK UP", value: "MOCK UP" },
  { label: "TENDER", value: "TENDER" },
];
