import { LoadState } from "../../../constants/enums";
import {
  IApprovalQuote,
  IApprovalQuoteLayout,
  IApprovalQuoteLayoutItem,
  IApprovalQuoteState,
} from "./approvalQuote.types";

export const defaultApprovalQuoteLayoutItem: IApprovalQuoteLayoutItem = {
  product_uuid: "",
  product_name: "",
  product_type: "",
  description: "",
  length: "",
  width: "",
  height: "",
  quantity: 100,
  price: 0,
  discount: 0,
  total: 0,
  attachment: "",
};

export const defaultApprovalQuoteLayout: IApprovalQuoteLayout = {
  title: "Layout",
  description: "",
  items: [defaultApprovalQuoteLayoutItem],
  attachment: "",
  discount_average: 0,
  price: 0,
  total: 0,
};

export const defaultApprovalQuote: IApprovalQuote = {
  enquiry_no: null,
  quote_items: [defaultApprovalQuoteLayout],
  quote_uuid: null,
  design_coordinator_uuid: null,
  design_coordinator_name: null,
  quote_no: "",
  quotation_type: null,
  payment_mode: null,
  remark: null,
  region: null,
  advising_bank: null,
  sales_type: null,
  approved_flag: null,
  approval_uuid: "",
  approved_by: null,
  approved_by_name: "",
  approved_by_uuid: "",
  discount_type: null,
  discount: null,
  full_amount: null,
  term_and_condition: null,
  discounted_value: null,
  roundoff_value: null,
  tax: null,
  total_amount_after_tax: null,
  assigned_to_uuid: null,
  secondary_assigned_to_uuid: null,
  customer_address_line1: "",
  customer_address_line2: "",
  customer_address_city: "",
  customer_address_state: "",
  customer_address_pincode: "",
  customer_address_country: "",
  delivery_address_line1: "",
  delivery_address_line2: "",
  delivery_address_city: "",
  delivery_address_state: "",
  delivery_address_pincode: "",
  delivery_address_country: "",
  customer_name: "",
  customer_type: "",
  status: "",
  created_by_uuid: "",
  quote_revision: "",
};

export const defaultApprovalQuoteState: IApprovalQuoteState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
};
