import { LoadState } from "../../constants/enums";
import { IDeviation, IDeviationState } from "./deviation.types";


export const initialDeviation: IDeviation = {
  deviation_uuid: null,
  deviation_type: "Process",
  doc_no: "",
  rev_no: null,
  requesting_department: "Production",
  other_requesting_department: "",
  reason: "Changes to the material Characteristics",
  other_reason: "",
  supplier_name: null,
  supplier_uuid: null,
  section: null,
  deviation_details: [
    {
      sale_order_no: "",
      project_name: "",
      product_code: "",
      description: "",
      quantity: ""
    }
  ],
  specifications: null,
  actual: null,
  root_cause: null,
  action_plan: null,
  prepared_by_uuid: null,
  prepared_by_name: null,
  approved_by_uuid: null,
  approved_by_name: null,
  checking_for_acceptance: [
    {
      qa_qc: {
        checked_name: "",
        checked_uuid: "",
        checked_date: ""
      },
      production: {
        checked_name: "",
        checked_uuid: "",
        checked_date: ""
      },
      engineering: {
        checked_name: "",
        checked_uuid: "",
        checked_date: ""
      },
      purchase: {
        checked_name: "",
        checked_uuid: "",
        checked_date: ""
      },
      planning: {
        checked_name: "",
        checked_uuid: "",
        checked_date: ""
      },
      logistics: {
        checked_name: "",
        checked_uuid: "",
        checked_date: ""
      },
      gm: {
        checked_name: "",
        checked_uuid: "",
        checked_date: ""
      },
      vp: {
        checked_name: "",
        checked_uuid: "",
        checked_date: ""
      }
    }
  ],
  remark: null,
  closed_date: null,
  status: "APPROVED",
};



export const initialDeviationState: IDeviationState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  deviation: {
    data: initialDeviation,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
