import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../../constants/enums";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { WORK_ORDER_JOB_ROUTE } from "./WorkOrderJob.constants";

const WorkOrderJobTable = Loadable(
  lazy(() =>
    import("./WorkOrderJobTable").then(({ WorkOrderJobTable }) => ({
      default: WorkOrderJobTable,
    })),
  ),
);
const ManageSingleWorkOrderJob = Loadable(
  lazy(() =>
    import("./ManageSingleWorkOrderJob").then(
      ({ ManageSingleWorkOrderJob }) => ({
        default: ManageSingleWorkOrderJob,
      }),
    ),
  ),
);

export const workOrderJobRoutes = [
  {
    path: WORK_ORDER_JOB_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <WorkOrderJobTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${WORK_ORDER_JOB_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <ManageSingleWorkOrderJob />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${WORK_ORDER_JOB_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <ManageSingleWorkOrderJob />
      </AuthorizedRoute>
    ),
  },
];
