import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IQueryParams } from "../../common/common.types";
import { IProductTemplate } from "./product-templates.types";
import { getSearchQuery } from "../../common/helpers";

export const FETCH_PRODUCT_TEMPLATE_LIST_PROGRESS =
  "FETCH_PRODUCT_TEMPLATE_LIST_PROGRESS";
export const FETCH_PRODUCT_TEMPLATE_LIST_SUCCESS =
  "FETCH_PRODUCT_TEMPLATE_LIST_SUCCESS";
export const FETCH_PRODUCT_TEMPLATE_LIST_FAILED =
  "FETCH_PRODUCT_TEMPLATE_LIST_FAILED";

export const fetchProductTemplatesListProgress = () =>
  action(FETCH_PRODUCT_TEMPLATE_LIST_PROGRESS);
export const fetchProductTemplatesListSuccess = (
  data: IProductTemplate[],
  totalRecords: number,
) => action(FETCH_PRODUCT_TEMPLATE_LIST_SUCCESS, { data, totalRecords });
export const fetchProductTemplatesListFailed = () =>
  action(FETCH_PRODUCT_TEMPLATE_LIST_FAILED);

export const fetchProductTemplatesListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchProductTemplatesListProgress());
      const res = await api.get(`/quotes/get-product-template${searchQuery}`);

      const data: IProductTemplate[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchProductTemplatesListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchProductTemplatesListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_SINGLE_PRODUCT_TEMPLATE_PROGRESS =
  "FETCH_SINGLE_PRODUCT_TEMPLATE_PROGRESS";
export const FETCH_SINGLE_PRODUCT_TEMPLATE_SUCCESS =
  "FETCH_SINGLE_PRODUCT_TEMPLATE_SUCCESS";
export const FETCH_SINGLE_PRODUCT_TEMPLATE_FAILED =
  "FETCH_SINGLE_PRODUCT_TEMPLATE_FAILED";

export const fetchSingleProductTemplateProgress = () =>
  action(FETCH_SINGLE_PRODUCT_TEMPLATE_PROGRESS);
export const fetchSingleProductTemplateSuccess = (data: IProductTemplate) =>
  action(FETCH_SINGLE_PRODUCT_TEMPLATE_SUCCESS, { data });
export const fetchSingleProductTemplateFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_PRODUCT_TEMPLATE_FAILED, { errorMessage });

export const fetchSingleProductTemplateAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleProductTemplateProgress());
      const res = await api.get(
        `/quotes/get-product-template?product_template_uuid=${uuid}`,
      );
      const data: IProductTemplate[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleProductTemplateSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleProductTemplateFailed(
            "Oops! We couldn't find any records.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleProductTemplateFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleProductsTemplateAsync =
  (
    template: IProductTemplate,
    onCallback: (isSuccess: boolean, template?: IProductTemplate) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const { create_ts, insert_ts, ...payload } = template;

      const res = await api.post("/quotes/upsert-product-template", payload);
      dispatch(
        showMessage({
          type: "success",
          message: "Product template saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_PRODUCT_TEMPLATE = "CLEAR_SINGLE_PRODUCT_TEMPLATE";
export const CLEAR_PRODUCT_TEMPLATE_LIST = "CLEAR_PRODUCT_TEMPLATE_LIST";
export const clearSingleProductTemplateAsync = () =>
  action(CLEAR_SINGLE_PRODUCT_TEMPLATE);
export const clearProductTemplateListAsync = () =>
  action(CLEAR_PRODUCT_TEMPLATE_LIST);
