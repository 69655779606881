import { ThunkAction } from "redux-thunk";
import { IEnquiryEmailHistory } from "./enquiry-emails.types";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { showMessage } from "../../messages/messagesActions";
import { action } from "typesafe-actions";

export const FETCH_EMAIL_HISTORY_PROGRESS = "FETCH_EMAIL_HISTORY_PROGRESS";
export const FETCH_EMAIL_HISTORY_SUCCESS = "FETCH_EMAIL_HISTORY_SUCCESS";
export const FETCH_EMAIL_HISTORY_FAILED = "FETCH_EMAIL_HISTORY_FAILED";

export const fetchEmailHistoryProgress = () =>
  action(FETCH_EMAIL_HISTORY_PROGRESS);
export const fetchEmailHistorySuccess = (
  list: IEnquiryEmailHistory[],
  totalRecords: number,
) => action(FETCH_EMAIL_HISTORY_SUCCESS, { list, totalRecords });
export const fetchEmailHistoryFailed = (errorMessage: string) =>
  action(FETCH_EMAIL_HISTORY_FAILED, { errorMessage });

export const fetchEmailHistoryAsync =
  (enquiry_no: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEmailHistoryProgress());
      const res = await api.get(
        `/history/get-email-history?module_uuid=${enquiry_no}&pageNo=1&itemPerPage=20`,
      );
      const data: IEnquiryEmailHistory[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchEmailHistorySuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchEmailHistoryFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_UNREAD_EMAILS_PROGRESS = "FETCH_UNREAD_EMAILS_PROGRESS";
export const FETCH_UNREAD_EMAILS_SUCCESS = "FETCH_UNREAD_EMAILS_SUCCESS";
export const FETCH_UNREAD_EMAILS_FAILED = "FETCH_UNREAD_EMAILS_FAILED";

export const fetchUnreadEmailsProgress = () =>
  action(FETCH_UNREAD_EMAILS_PROGRESS);
export const fetchUnreadEmailsSuccess = () =>
  action(FETCH_UNREAD_EMAILS_SUCCESS);
export const fetchUnreadEmailsFailed = (errorMessage: string) =>
  action(FETCH_UNREAD_EMAILS_FAILED, { errorMessage });

export const fetchUnreadEmailsAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchUnreadEmailsProgress());
      await api.get(`/general/microsoftgraph/fetchUnreadEmails`);

      dispatch(fetchUnreadEmailsSuccess());
    } catch (err: any) {
      dispatch(fetchUnreadEmailsFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_EMAIL_HISTORY_STATE = "CLEAR_EMAIL_HISTORY_STATE";
export const CLEAR_EMAIL_HISTORY = "CLEAR_EMAIL_HISTORY";

export const clearEmailHistoryState = () => action(CLEAR_EMAIL_HISTORY_STATE);
export const clearEmailHistory = () => action(CLEAR_EMAIL_HISTORY);
