import React, { useState } from "react";
import { IAddNewUserProps } from "./AddNewUser.types";
import { useDispatchWrapper } from "../../../hooks";
import { useFormik } from "formik";
import { Dialog } from "../../../components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../components/formsComponents";
import { createNewUserAsync } from "../../../redux/UserProfileList/userProfileActions";
import { IUserCreation } from "../../../redux/UserProfileList/userProfile.types";
import { useNavigate } from "react-router-dom";
import { renderDropdownValue } from "../../../helpers";
import { RoleIdAutoSearch } from "../../../components/AutoCompleteSearches/RoleIdAutoSearch";
import LoadingDialog from "../../../components/Dialogs/LoadingDialog";
import { UserBranchAutoSearch } from "../../../components/AutoCompleteSearches/UserBranchAutoSearch";

const INITIAL_STATE: IUserCreation = {
  first_name: "",
  last_name: "",
  email: "",
  user_password: "",
  confirmPassword: "",
  branch_uuid: "",
  branch_name: "",
  status: "ACTIVE",
  role_uuid: 0,
};

export const AddNewUserDialog: React.FC<IAddNewUserProps> = (props) => {
  const { open, onClose } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const {
    values,
    errors,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: { ...INITIAL_STATE, confirmPassword: "" },
    validate: (values) => {
      const errors: any = {};
      if (!values.first_name) {
        errors.first_name = "First name is required.";
      } else if (!values.last_name) {
        errors.last_name = "Last name is required.";
      } else if (!values.email) {
        errors.email = "Email is required.";
      } else if (
        values.email !== "" &&
        !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          values.email,
        )
      ) {
        errors.email = "*Invalid email address.";
      } else if (!values.user_password) {
        errors.user_password = "Password is required.";
      } else if (values.user_password.length < 6) {
        errors.user_password = "Password must be at least 6 characters long.";
      } else if (!values.confirmPassword) {
        errors.confirmPassword = "Confirm password is required.";
      } else if (values.user_password !== values.confirmPassword) {
        errors.confirmPassword = "Passwords are not matching.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        createNewUserAsync(
          {
            ...values,
          },
          (isSuccess, user_uuid) => {
            if (isSuccess && user_uuid) {
              navigate("/users/manage/" + user_uuid);
              onClose();
            }
            setSaveLoading(false);
          },
        ),
      );
    },
  });

  const handleUserCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = e.target.value;
    const alphaNumericText = inputText.replace(/[^A-Za-z0-9]/g, "");

    setFieldValue("user_code", alphaNumericText.toUpperCase());
  };

  return (
    <Dialog
      open={open}
      title="Create New User"
      onClose={onClose}
      size="md"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        { type: "button", label: "Close", variant: "text", onClick: onClose },
        {
          type: "submit",
          label: "Save",
          variant: "contained",
          disabled: saveLoading,
        },
      ]}
    >
      <Grid container spacing={1}>
        <LoadingDialog open={saveLoading} />
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>First Name </CustomFormLabel>
          <CustomTextField
            name="first_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.first_name}
            error={errors.first_name ? true : false}
            helperText={errors.first_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Last Name </CustomFormLabel>
          <CustomTextField
            name="last_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.last_name}
            error={errors.last_name ? true : false}
            helperText={errors.last_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Email </CustomFormLabel>
          <CustomTextField
            name="email"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.email}
            error={errors.email ? true : false}
            helperText={errors.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Password </CustomFormLabel>
          <CustomTextField
            name="user_password"
            variant="outlined"
            size="small"
            type="password"
            fullWidth
            value={values.user_password}
            error={errors.user_password ? true : false}
            helperText={errors.user_password}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Confirm Password</CustomFormLabel>
          <CustomTextField
            name="confirmPassword"
            variant="outlined"
            size="small"
            type="password"
            fullWidth
            value={values.confirmPassword}
            error={errors.confirmPassword ? true : false}
            helperText={errors.confirmPassword}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <UserBranchAutoSearch
            label="Branch"
            error={errors.branch_uuid}
            value={{
              branch_uuid: values.branch_uuid,
              branch_name: values.branch_name,
            }}
            onSelect={(value) => {
              setValues({
                ...values,
                branch_uuid: value.branch_uuid as string,
                branch_name: value.branch_name as string,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>Role Name</CustomFormLabel>
          <RoleIdAutoSearch
            value={values.role_uuid}
            onSelect={(newValue) => {
              setFieldValue("role_uuid", newValue.value);
            }}
          />
          {/* <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="role_id"
            fullWidth
            value={values.role_id}
            onChange={handleChange}
            displayEmpty
            placeholder="Select one"
            renderValue={renderDropdownValue(`Select One`)}
            options={[
              { label: "0", value: 0 },
              { label: "1", value: 1 },
              { label: "2", value: 2 },
            ]}
          /> */}
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>Status</CustomFormLabel>
          <ControlledCustomSelect
            variant="outlined"
            size="small"
            type="string"
            name="status"
            fullWidth
            value={values.status}
            onChange={handleChange}
            displayEmpty
            placeholder="Select one"
            renderValue={renderDropdownValue(`Select One`)}
            options={[
              { label: "Active", value: "ACTIVE" },
              { label: "Inactive", value: "INACTIVE" },
            ]}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
