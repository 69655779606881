import { action } from "typesafe-actions";
import { ITaskBaord, ITaskBoardItem, ITaskListItem } from "./taskBoard.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";

export const FETCH_TASK_LIST_PROGRESS = "FETCH_TASK_LIST_PROGRESS";
export const FETCH_TASK_LIST_SUCCESS = "FETCH_TASK_LIST_SUCCESS";
export const FETCH_TASK_LIST_FAILED = "FETCH_TASK_LIST_FAILED";

export const fetchTaskListProgress = () => action(FETCH_TASK_LIST_PROGRESS);
export const fetchTaskListSuccess = (
  data: ITaskListItem[],
  totalRecords: number,
) => action(FETCH_TASK_LIST_SUCCESS, { data, totalRecords });
export const fetchTaskListFailed = () => action(FETCH_TASK_LIST_FAILED);

export const fetchTaskListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskListProgress());
      const searchQuery = getSearchQuery(queryParams);
      const res = await api.get(`/task/get-task-list${searchQuery}`);
      const data: ITaskListItem[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchTaskListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchTaskListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_TASKBOARD_PROGRESS = "FETCH_TASKBOARD_PROGRESS";
export const FETCH_TASKBOARD_SUCCESS = "FETCH_TASKBOARD_SUCCESS";
export const FETCH_TASKBOARD_FAILED = "FETCH_TASKBOARD_FAILED";

export const fetchTaskboardProgress = () => action(FETCH_TASKBOARD_PROGRESS);
export const fetchTaskboardSuccess = (data: ITaskBaord) =>
  action(FETCH_TASKBOARD_SUCCESS, { data });
export const fetchTaskboardFailed = () => action(FETCH_TASKBOARD_FAILED);

export const fetchTaskbaordAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchTaskboardProgress());
      const res = await api.get(`/task/get-task`);
      const data: ITaskBaord = res.data.data;
      dispatch(fetchTaskboardSuccess(data));
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const UPSERT_TASKBOARD_SUCCESS = "UPSERT_TASKBOARD_SUCCESS";
export const upsertTaskboardSuccess = (
  data: ITaskBoardItem,
  mode: "update" | "new" | "delete",
  status?: ITaskBoardItem["status"],
) => action(UPSERT_TASKBOARD_SUCCESS, { data, mode, status });
export const upsertTaskBoardAsync =
  (
    taskBoardItem: ITaskBoardItem,
    mode: "update" | "new" | "delete",
    onCallback: (isSuccess: boolean) => void,
    status?: ITaskBoardItem["status"],
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { id, create_ts, insert_ts, ...payload } = taskBoardItem;
      const response = await api.post("/task/upsert-task", payload);
      dispatch(upsertTaskboardSuccess(response.data.data, mode, status));
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const editTaskbaordAync =
  (
    task: ITaskBoardItem,
    key: "TODO" | "PROGRESS" | "HOLD" | "COMPLETED",
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const taskBoard = getState().taskBoard.data;
    try {
      const tasks = [...taskBoard[key as "TODO"]];
      const index = tasks.findIndex((x) => x.id === task.id);
      if (index > -1) {
        tasks[index] = task;
        const finalTaskBaord = { ...taskBoard, [key]: tasks };
        dispatch(fetchTaskboardSuccess(finalTaskBaord));
        dispatch(upsertTaskBoardAsync(task, "update", onCallback));
      } else {
        onCallback(false);
      }
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
