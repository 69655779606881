import moment from "moment";
import {
  STANDARD_APP_DATE_FORMAT,
  STANDARD_APP_DATE_TIME_FORMAT,
} from "../constants/constants";
import { String } from "lodash";

export const convertDate = (targetDate: string) => {
  let date = new Date(targetDate);

  date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  console.log(`${date.getFullYear()}-0${date.getMonth()}-${date.getDate()}`);

  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
};

export const formatDate = (date: string) => {
  return moment(date).format(STANDARD_APP_DATE_FORMAT);
};

export const formatDateWithTime = (date: string) => {
  return moment(date).format(STANDARD_APP_DATE_TIME_FORMAT);
};

export const daysToExpiry = (date_time: string): string | null => {
  const current_date = new Date();
  const dayDiff = moment(date_time).diff(current_date, "days");
  const secDiff = moment(date_time).diff(current_date, "second");

  if (secDiff < 0) {
    return `Expired ${moment(date_time).fromNow()}`;
  }
  if (dayDiff < 5) {
    return `Expire ${moment(date_time).fromNow()}`;
  }
  return null;
};
