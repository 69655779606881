import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IEcn, IEcnState } from "./ecn.types";

export const FETCH_ECN_LIST_PROGRESS = "FETCH_ECN_LIST_PROGRESS";
export const FETCH_ECN_LIST_SUCCESS = "FETCH_ECN_LIST_SUCCESS";
export const FETCH_ECN_LIST_FAILED = "FETCH_ECN_LIST_FAILED";

export const fetchEcnListProgress = () => action(FETCH_ECN_LIST_PROGRESS);

export const fetchEcnListSuccess = (
  data: IEcnState["list"]["data"],
  totalRecords: number,
) => action(FETCH_ECN_LIST_SUCCESS, { data, totalRecords });
export const fetchEcnListFailed = () => action(FETCH_ECN_LIST_FAILED);

export const fetchEcnListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchEcnListProgress());
      const res = await api.get(`/deviation/get-ecn${searchQuery}`);
      const data: IEcnState["list"]["data"] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchEcnListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchEcnListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_ECN_PROGRESS = "FETCH_ECN_PROGRESS";
export const FETCH_ECN_SUCCESS = "FETCH_ECN_SUCCESS";
export const FETCH_ECN_FAILED = "FETCH_ECN_FAILED";

export const fetchEcnProgress = () => action(FETCH_ECN_PROGRESS);
export const fetchEcnSuccess = (data: IEcn) =>
  action(FETCH_ECN_SUCCESS, { data });
export const fetchEcnFailed = (errorMessage: string) =>
  action(FETCH_ECN_FAILED, { errorMessage });

export const fetchEcnAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEcnProgress());
      const res = await api.get(`/deviation/get-ecn?ecn_uuid=${uuid}`);
      const data: IEcn[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchEcnSuccess(data[0]));
      } else {
        dispatch(fetchEcnFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchEcnFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertEcnAsync =
  (
    data: IEcn,
    onCallback: (isSuccess: boolean, deviation?: IEcn) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post("/deviation/upsert-ecn", payload);
      let message = "ECN saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const upsertRequestDeviationAsync =
  (
    enquiryNo: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/bom/upsert-request-bom", {
        enquiry_no: enquiryNo,
        status: "BOM_REQUESTED",
      });
      let message = "Deviation requested successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_ECN = "CLEAR_ECN";
export const CLEAR_ECN_STATE = "CLEAR_ECN_STATE";
export const clearEcn = () => action(CLEAR_ECN);
export const clearEcnState = () => action(CLEAR_ECN_STATE);
