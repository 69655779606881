import produce from "immer";

import { DeviationActions, initialDeviation, initialDeviationState } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { CLEAR_DEVIATION, CLEAR_DEVIATION_STATE, FETCH_DEVIATION_FAILED, FETCH_DEVIATION_LIST_FAILED, FETCH_DEVIATION_LIST_PROGRESS, FETCH_DEVIATION_LIST_SUCCESS, FETCH_DEVIATION_PROGRESS, FETCH_DEVIATION_SUCCESS } from "./deviationActions";


export const deviationReducer = (
  state: IStoreState["deviation"] = initialDeviationState,
  action: DeviationActions,
) => {
  switch (action.type) {
    case FETCH_DEVIATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_DEVIATION_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_DEVIATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_DEVIATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.deviation.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_DEVIATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.deviation.loading = LoadState.Loaded;
        draftState.deviation.data = data;
      });
      return newState;
    }
    case FETCH_DEVIATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.deviation.loading = LoadState.Failed;
        draftState.deviation.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_DEVIATION: {
      const newState = produce(state, (draftState) => {
        draftState.deviation.loading = LoadState.NotLoaded;
        draftState.deviation.data = initialDeviation;
        draftState.deviation.error = null;
      });
      return newState;
    }

    case CLEAR_DEVIATION_STATE: {
      return initialDeviationState;
    }

    default: {
      return state;
    }
  }
};
