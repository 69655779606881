import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { MODULE_IDS } from "../../../constants/enums";
import { PROFORMA_INVOICE_ROUTE } from "./ProformaInvoices.constants";

const ProformaInvoicesTable = Loadable(
  lazy(() =>
    import("./ProformaInvoicesTable").then(({ ProformaInvoicesTable }) => ({
      default: ProformaInvoicesTable,
    })),
  ),
);
const ManageSingleProformaInvoice = Loadable(
  lazy(() =>
    import("./ManageSingleProformaInvoice").then(
      ({ ManageSingleProformaInvoice }) => ({
        default: ManageSingleProformaInvoice,
      }),
    ),
  ),
);

export const proformaInvoicesRoutes = [
  {
    path: PROFORMA_INVOICE_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <ProformaInvoicesTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PROFORMA_INVOICE_ROUTE}/manage/:enq_no/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <ManageSingleProformaInvoice />
      </AuthorizedRoute>
    ),
  },
];
