import { Box, Chip } from "@mui/material";
import React from "react";
import { ICustomChipProps } from "./interfaces/ICustomChipProps";
import { CustomFormLabel } from "../formsComponents";

export const CustomChip: React.FC<ICustomChipProps> = (props) => {
  const { size, content, color, icon, sx } = props;
  return (
    <Chip
      sx={{
        color: "#fff",
        borderRadius: "6px",
        pl: "3px",
        pr: "3px",
        ...sx,
      }}
      //@ts-ignore
      icon={icon}
      color={color}
      size={size}
      label={content}
    />
  );
};

export const CustomChipWithLabel: React.FC<{
  title?: string;
  value: string;
  variant?: "h5";
  size?: "small" | "medium";
}> = (props) => {
  const { title, value, variant, size } = props;
  return (
    <>
      <CustomFormLabel>{title}</CustomFormLabel>
      <Chip
        sx={{
          backgroundColor: "primary.main",
          color: "#fff",
          borderRadius: 1,
          fontWeight: 600,
          // width: '100%'
          minHeight: 38,
        }}
        label={value}
        content={value}
        size={size}
      />
    </>
  );
};
