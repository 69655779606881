import { action } from "typesafe-actions";
import { IApprovalQuote, IHandleApproval } from "./approvalQuote.types";
import { IDate } from "../../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { AnyAction } from "redux";

export const FETCH_APPROVAL_QUOTE_LIST_PROGRESS =
  "FETCH_APPROVAL_QUOTE_LIST_PROGRESS";
export const FETCH_APPROVAL_QUOTE_LIST_SUCCESS =
  "FETCH_APPROVAL_QUOTE_LIST_SUCCESS";
export const FETCH_APPROVAL_QUOTE_LIST_FAILED =
  "FETCH_APPROVAL_QUOTE_LIST_FAILED";

export const fetchApprovalQuotesListProgress = () =>
  action(FETCH_APPROVAL_QUOTE_LIST_PROGRESS);
export const fetchApprovalQuotesListSuccess = (
  list: IApprovalQuote[],
  totalRecords: number,
) => action(FETCH_APPROVAL_QUOTE_LIST_SUCCESS, { list, totalRecords });
export const fetchApprovalQuotesListFailed = () =>
  action(FETCH_APPROVAL_QUOTE_LIST_FAILED);

export const fetchApprovalQuotesListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchApprovalQuotesListProgress());
        let url = "";
        if (status !== "-1") {
          url = "&status=" + status;
        }
        let finalUrl = `/approval/get-approval?table_name=latest_quotes&status=REQUESTED&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
        if (searchType.length > 0 && searchValue) {
          finalUrl = `/quotes/get-quotes?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&columns=${[
            searchType,
          ]}&value=${searchValue}`;
        }

        const res = await api.get(finalUrl);
        const data: IApprovalQuote[] = res.data.data;
        const totalRecords = res.data.totalRecords;

        dispatch(fetchApprovalQuotesListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchApprovalQuotesListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const approvalAsync =
  (
    data: IHandleApproval,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(saveLoaderProgress());
        await api.post("/enquiry/approve-orf-update-table", data);
        let message = "Action success!";
        dispatch(
          showMessage({
            type: "success",
            displayAs: "snackbar",
            message: message,
          }),
        );
        onCallback(true);
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      } finally {
        dispatch(saveLoaderCompleted());
      }
    };

export const handleApprovalAsync =
  (
    data: IHandleApproval,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(saveLoaderProgress());
        const { costingSheetUUID, ...rest } = data;
        const res = await api.post("/approval/handle-approval", rest);
        console.log(res, "res");
        if (res.status === 200 && data?.fields?.second_approval_uuid) {
          api.get(`/quotes/get-costing-sheet-preview?costing_sheet_uuid=${data.costingSheetUUID}&isPreviewOrUpload=UPLOAD_PDF`)
        }
        let message = "Action success!";
        dispatch(
          showMessage({
            type: "success",
            displayAs: "snackbar",
            message: message,
          }),
        );

        onCallback(true);
      } catch (err: any) {
        onCallback(true);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      } finally {
        dispatch(saveLoaderCompleted());
      }
    };

export const CLEAR_APPROVAL_QUOTE_STATE = "CLEAR_APPROVAL_QUOTE_STATE";
export const clearApprovalQuoteState = () => action(CLEAR_APPROVAL_QUOTE_STATE);
