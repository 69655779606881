import { LoadState } from "../../../constants/enums";
import { IProductAddOn, IProductAddOnState } from "./product-addon.types";

export const defaultProductAddOn: IProductAddOn = {
  product_add_on_uuid: null,
  product_add_on_name: "",
  product_add_on_price: null,
  product_uuid: null,
  product_name: null,
};

export const defaultProductAddOnState: IProductAddOnState = {
  product_add_on_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_product_add_on: {
    data: defaultProductAddOn,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
