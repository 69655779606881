import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import {
  CLEAR_ORDER_PROCESSING_LIST,
  FETCH_ORDER_PROCESSING_LIST_FAILED,
  FETCH_ORDER_PROCESSING_LIST_PROGRESS,
  FETCH_ORDER_PROCESSING_LIST_SUCCESS,
} from "./orderProcessing.actions";
import { defaultOrderProcessingState } from "./defaultState";
import { OrderProcessingActions } from ".";

export const orderProcessingReducer = (
  state: IStoreState["orderProcessing"] = defaultOrderProcessingState,
  action: OrderProcessingActions,
) => {
  switch (action.type) {
    // ----------------------------------  Comment List----------------------------------
    case FETCH_ORDER_PROCESSING_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.salesOrders.loading = LoadState.InProgress;
        draftState.salesOrders.data = [];
      });
      return newState;
    }
    case FETCH_ORDER_PROCESSING_LIST_SUCCESS: {
      const { data, count } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.salesOrders.loading = LoadState.Loaded;
        draftState.salesOrders.data = data;
        draftState.salesOrders.totalRecords = count;
      });
      return newState;
    }
    case FETCH_ORDER_PROCESSING_LIST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.salesOrders.loading = LoadState.Failed;
        draftState.salesOrders.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_ORDER_PROCESSING_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.salesOrders.loading = LoadState.NotLoaded;
        draftState.salesOrders.data = [];
        draftState.salesOrders.error = null;
      });
      return newState;
    }

    // ---------------------------------- Default ----------------------------------
    default: {
      return state;
    }
  }
};
