import { Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { StandardCard } from "../../../components/Cards";
import { ILocationResponsePayload, LocationAutoComplete } from "../../../components/LocationAutoComplete/LocationAutoComplete";
import { PageLoader } from "../../../components/PageLoader/PageLoader";
import { TextFieldWithLabel } from "../../../components/TextFieldWithLabel/TextFieldWithLabel";
import { PageContainer } from "../../../components/container/PageContainer";
import { CustomFormLabel, CustomTextField } from "../../../components/formsComponents";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import { useDispatchWrapper } from "../../../hooks";
import { Breadcrumb } from "../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { clearUserBranch, fetchUserBranchAsync, upsertUserBranchAysnc } from "../../../redux/DataManagement/dataManagementActions";
import { IStoreState } from "../../../redux/initialStoreState";
import { RoleBasedCustomButton } from "../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import LoadingDialog from "../../../components/Dialogs/LoadingDialog";

export const ManageUserBranch = () => {
    const { branchUUId } = useParams() as { branchUUId?: string }
    const isUpdate = branchUUId ? true : false;
    const BCrumb: IBreadcrumbProps["items"] = [
        {
            to: "/dashboard",
            title: "dashboard",
        },
        {
            to: "/user-branch",
            title: "user-branch",
        },
        {
            title: isUpdate ? "Edit Branch" : "Create Branch",
        },
    ];
    const dispatch = useDispatchWrapper();
    const navigate = useNavigate();
    const { data, error, loading: branchLoading } = useSelector((storeState: IStoreState) => storeState.dataManagement.branch)
    const [loading, setLoading] = useState(false);

    const {
        values,
        errors,
        setValues,
        handleChange,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: data,
        validate: (values) => {
            const errors: any = {};

            return errors;
        },
        onSubmit: async (values) => {
            setLoading(true);
            dispatch(
                upsertUserBranchAysnc({ ...values, branch_uuid: values.branch_uuid }, (isSuccess) => {
                    if (isSuccess) {
                        navigate(`/user-branch`)
                    }
                    setLoading(true);
                }),
            );
        },
    });

    const handleLocation = (data: ILocationResponsePayload) => {
        setValues({
            ...values,
            billing_address_line2: data.address,
            billing_city: data.city,
            billing_state: data.state,
            billing_pincode: data.postalCode,
            billing_country: data.country
        })
    }

    const handleDeliveryLocation = (data: ILocationResponsePayload) => {
        setValues({
            ...values,
            delivery_address_line2: data.address,
            delivery_address_city: data.city,
            delivery_address_state: data.state,
            delivery_address_pincode: data.postalCode,
            delivery_address_country: data.country
        })
    }

    useEffect(() => {
        setValues(data)
    }, [data])
    useEffect(() => {
        if (branchUUId) {
            dispatch(fetchUserBranchAsync(branchUUId))
        }
    }, [branchUUId])

    useEffect(() => {
        return () => {
            dispatch(clearUserBranch());
        };
    }, []);
    return (
        <PageLoader
            loading={branchLoading === LoadState.InProgress}
            error={error ? { message: error } : null}
        >
            <PageContainer
                title={isUpdate ? "Update Branch" : "Create Branch"}
                description="this is inner page"
            >
                <Breadcrumb title="Manage User Branch" items={BCrumb} />
                <StandardCard
                    sx={{ ml: 2, mr: 2 }}
                    heading={`${isUpdate ? "Update" : "Create"} Branch`}
                >
                    
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={4}>
                                <TextFieldWithLabel
                                    heading={"Branch Name"}
                                    type="text"
                                    id="branch_name"
                                    fullWidth
                                    value={values.branch_name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextFieldWithLabel
                                    heading={"Description"}
                                    type="text"
                                    id="description"
                                    fullWidth
                                    value={values.description}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextFieldWithLabel
                                    heading={"Legal Entry"}
                                    type="text"
                                    id="legal_entity"
                                    fullWidth
                                    value={values.legal_entity}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextFieldWithLabel
                                    heading={"Alternate Name"}
                                    type="text"
                                    id="alternate_name"
                                    fullWidth
                                    value={values.alternate_name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextFieldWithLabel
                                    heading={"Region"}
                                    type="text"
                                    id="region"
                                    fullWidth
                                    value={values.region}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextFieldWithLabel
                                    heading={"Sub Region"}
                                    type="text"
                                    id="sub_region_or_country"
                                    fullWidth
                                    value={values.sub_region_or_country}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextFieldWithLabel
                                    heading={"City"}
                                    type="text"
                                    id="city"
                                    fullWidth
                                    value={values.city}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextFieldWithLabel
                                    heading={"Zip Code"}
                                    type="number"
                                    id="pin_zip_code"
                                    fullWidth
                                    value={values.pin_zip_code}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextFieldWithLabel
                                    heading={"Service Tax Category"}
                                    type="text"
                                    id="service_tax_category"
                                    fullWidth
                                    value={values.service_tax_category}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextFieldWithLabel
                                    heading={"Import Export Code"}
                                    type="number"
                                    id="import_export_code_iec"
                                    fullWidth
                                    value={values.import_export_code_iec}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <CustomFormLabel>Billing Location</CustomFormLabel>
                                <LocationAutoComplete
                                    value={values.billing_address_line2}
                                    onLocationChange={handleLocation}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextFieldWithLabel
                                    heading={"Billing Address Line 1"}
                                    type="text"
                                    id="billing_address_line1"
                                    fullWidth
                                    value={values.billing_address_line1}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextFieldWithLabel
                                    heading={"Billing Address Line 2"}
                                    type="text"
                                    id="billing_address_line2"
                                    fullWidth
                                    value={values.billing_address_line2}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextFieldWithLabel
                                    heading={"Billing City"}
                                    type="text"
                                    id="billing_city"
                                    fullWidth
                                    value={values.billing_city}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextFieldWithLabel
                                    heading={"Billing State"}
                                    type="text"
                                    id="billing_state"
                                    fullWidth
                                    value={values.billing_state}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} lg={3}>
                                <TextFieldWithLabel
                                    heading={"Billing Pin Code"}
                                    type="text"
                                    id="billing_pincode"
                                    fullWidth
                                    value={values.billing_pincode}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} lg={3}>
                                <TextFieldWithLabel
                                    heading={"Billing Country"}
                                    type="text"
                                    id="billing_country"
                                    fullWidth
                                    value={values.billing_country}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <CustomFormLabel>Delivery Location</CustomFormLabel>
                                <LocationAutoComplete
                                    value={values.delivery_address_line2}
                                    onLocationChange={handleDeliveryLocation}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextFieldWithLabel
                                    heading={"Delivery Address Line 1"}
                                    type="text"
                                    id="delivery_address_line1"
                                    fullWidth
                                    value={values.delivery_address_line1}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextFieldWithLabel
                                    heading={"Delivery Address Line 2"}
                                    type="text"
                                    id="delivery_address_line2"
                                    fullWidth
                                    value={values.delivery_address_line2}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextFieldWithLabel
                                    heading={"Delivery City"}
                                    type="text"
                                    id="delivery_address_city"
                                    fullWidth
                                    value={values.delivery_address_city}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <TextFieldWithLabel
                                    heading={"Delivery State"}
                                    type="text"
                                    id="delivery_address_state"
                                    fullWidth
                                    value={values.delivery_address_state}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} lg={3}>
                                <TextFieldWithLabel
                                    heading={"Delivery Pin Code"}
                                    type="text"
                                    id="delivery_address_pincode"
                                    fullWidth
                                    value={values.delivery_address_pincode}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} lg={3}>
                                <TextFieldWithLabel
                                    heading={"Delivery Country"}
                                    type="text"
                                    id="billing_country"
                                    fullWidth
                                    value={values.delivery_address_country}
                                    onChange={handleChange}
                                />
                            </Grid>

                        </Grid>
                        <Stack direction={"row"} spacing={2} marginTop={4}>
                            <RoleBasedCustomButton
                                moduleId={MODULE_IDS.USERS}
                                type="submit"
                                disabled={loading}
                                variant="contained"
                            >
                                Save
                            </RoleBasedCustomButton>
                            <LoadingDialog open={loading} />
                        </Stack>
                    </form>
                </StandardCard>
            </PageContainer>
        </PageLoader>
    )
}