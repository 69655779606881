import produce from "immer";

import {
  CLEAR_ECN,
  CLEAR_ECN_STATE,
  FETCH_ECN_FAILED,
  FETCH_ECN_LIST_FAILED,
  FETCH_ECN_LIST_PROGRESS,
  FETCH_ECN_LIST_SUCCESS,
  FETCH_ECN_PROGRESS,
  FETCH_ECN_SUCCESS,
} from "./ecnActions";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultEcn, defaultEcnState } from "./defaultState";
import { EcnActions } from ".";

export const ecnReducer = (
  state: IStoreState["ecn"] = defaultEcnState,
  action: EcnActions,
) => {
  switch (action.type) {
    case FETCH_ECN_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_ECN_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_ECN_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_ECN_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.ecn.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ECN_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.ecn.loading = LoadState.Loaded;
        draftState.ecn.data = data;
      });
      return newState;
    }
    case FETCH_ECN_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.ecn.loading = LoadState.Failed;
        draftState.ecn.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_ECN: {
      const newState = produce(state, (draftState) => {
        draftState.ecn.loading = LoadState.NotLoaded;
        draftState.ecn.data = defaultEcn;
        draftState.ecn.error = null;
      });
      return newState;
    }

    case CLEAR_ECN_STATE: {
      return defaultEcnState;
    }

    default: {
      return state;
    }
  }
};
