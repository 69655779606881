import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { showMessage } from "../../messages/messagesActions";
import {
  IOrderProcessing,
  IUpsertOrderProcessing,
} from "./orderProcessing.types";
import { IQueryParams } from "../../common/common.types";
import { getSearchQuery } from "../../common/helpers";

//****************************** Fetch Single Comment ******************************//
export const FETCH_ORDER_PROCESSING_LIST_PROGRESS =
  "FETCH_ORDER_PROCESSING_LIST_PROGRESS";
export const FETCH_ORDER_PROCESSING_LIST_SUCCESS =
  "FETCH_ORDER_PROCESSING_LIST_SUCCESS";
export const FETCH_ORDER_PROCESSING_LIST_FAILED =
  "FETCH_ORDER_PROCESSING_LIST_FAILED";

export const fetchOrderProcessingListProgress = () =>
  action(FETCH_ORDER_PROCESSING_LIST_PROGRESS);
export const fetchOrderProcessingListSuccess = (
  data: IOrderProcessing[],
  count: number,
) => action(FETCH_ORDER_PROCESSING_LIST_SUCCESS, { data, count });
export const fetchOrderProcessingListFailed = (errorMessage: string) =>
  action(FETCH_ORDER_PROCESSING_LIST_FAILED, { errorMessage });

export const fetchOrderProcessingListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchOrderProcessingListProgress());
      const res = await api.get(`/enquiry/get-order-processing${searchQuery}`); // need to be replaced
      const data = res.data.data.result;
      dispatch(fetchOrderProcessingListSuccess(data, res.data.totalRecords));
    } catch (err: any) {
      dispatch(fetchOrderProcessingListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_ORDER_PROCESSING_LIST = "CLEAR_ORDER_PROCESSING_LIST";
export const clearOrderProcessingListAsync = () =>
  action(CLEAR_ORDER_PROCESSING_LIST);

export const upsertOrderProcessingAsync =
  (
    order: IUpsertOrderProcessing,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.post("/enquiry/upsert-order-processing", order);
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: `Order ${
            order.status === "ORDER" ? "created" : "rejected"
          } successfully!`,
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      // dispatch(saveLoaderCompleted());
    }
  };
