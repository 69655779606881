import { LoadState } from "../../constants/enums";
import { IBranch, IDataManagementState, IZone } from "./dataManagement.types";

export const defaultUserBranch: IBranch = {
    branch_name: "",
    branch_uuid: null,
    description: null,
    legal_entity: "",
    alternate_name: "",
    region: "",
    sub_region_or_country: "",
    city: "",
    pin_zip_code: "",
    billing_address_line1: "",
    billing_address_line2: "",
    billing_city: "",
    billing_state: "",
    billing_pincode: "",
    billing_country: "",
    delivery_address_line1: "",
    delivery_address_line2: "",
    delivery_address_city: "",
    delivery_address_state: "",
    delivery_address_pincode: "",
    delivery_address_country: "",
    import_export_code_iec: "",
    pan_no: "",
    gst_no: "",
    service_tax_category: "",
    status: "ACTIVE"
}
export const defaultUserZone: IZone = {
    zone_name: " ",
    branches_uuid: [
        {
            branch_name: "",
            branch_uuid: ""
        }
    ],
    zone_uuid: null,
    status: "ACTIVE"
}

export const defaultDataManagementState: IDataManagementState = {
    branchList: {
        list: [],
        totalRecords: 0,
        loading: LoadState.NotLoaded,
        error: null,
    },
    branch: {
        data: defaultUserBranch,
        loading: LoadState.NotLoaded,
        error: null,
    },
    zoneList: {
        list: [],
        totalRecords: 0,
        loading: LoadState.NotLoaded,
        error: null,
    },
    zone: {
        data: defaultUserZone,
        loading: LoadState.NotLoaded,
        error: null,
    }
}