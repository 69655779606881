import {
  IEnquiryQRF,
  IEnquiryQrfProduct,
  IEnquiryQrfProductItem,
} from "./enquiry-qrf.types";

export const defaultEnquiryQrfProductItem: IEnquiryQrfProductItem = {
  description: "",
  specifications: "",
  quantity: 0,
  target_price: 0,
  amount: 0,
};

export const defaultEnquiryQrfProduct: IEnquiryQrfProduct = {
  product_template_uuid: null,
  template_name: "",
  description: "",
  product_items: [],
};
export const defaultEnquiryQRF: IEnquiryQRF = {
  qrf_uuid: null,
  enquiry_no: "",
  customer_uuid: null,
  customer_name: null,
  no_of_options_required: null,
  condition_of_delivery: null,
  sales_rep_uuid: null,
  sales_rep: null,
  contact_uuid: null,
  contact_name: null,
  condition_of_payment: null,
  product_items: [defaultEnquiryQrfProduct],
  attachments: [],
  issued_by: null,
  checked_by: null,
  remark: null,
  notes: null
  
};
