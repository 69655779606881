import { LoadState } from "../../constants/enums";
import { IBom, IBomProduct, IBomState } from "./bom.types";

export const defaultBomProduct: IBomProduct = {
  level: 0,
  part_code: "",
  drawing: "",
  part_name: "",
  product_size: {
    height: 0,
    width: 0,
    depth: 0,
  },
  meaning: "",
  item_type: "",
  quantity_per_unit: 0,
  unit: "",
};
export const initialBom: IBom = {
  bom_uuid: "",
  product_code: "",
  order_no: "",
  product_size: null,
  series: null,
  rev_no: null,
  effective_date: null,
  description: null,
  add: null,
  change: null,
  delete: null,
  prepared_by_uuid: null,
  prepared_by_name: null,
  checked_by_uuid: null,
  checked_by_name: null,
  approved_by_uuid: null,
  approved_by_name: null,
  excel_link: null,
  approval_status: null,
  status: "BOM_REQUESTED",
  product_items: [defaultBomProduct],
};

export const initialBomState: IBomState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  bom: {
    data: initialBom,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
