import { IProject } from "./project.types";

export const defaultProject: IProject = {
  project_id: null,
  project_uuid: null,
  project_name: null,
  created_by_uuid: null,
  originated_by: null,
  employee_or_dealer_uuid: null,
  employee_or_dealer_name: null,
  dealer_name: null,
  dealer_uuid: null,
  architect_firm_uuid: null,
  architect_firm_name: null,
  architect_name_uuid: null,
  architect_name_name: null,
  pmc_firm_uuid: null,
  pmc_firm_name: null,
  pmc_name_uuid: null,
  pmc_name_name: null,

  type: null,
  time_frame_of_closing: null,

  sales_location: null,
  sales_invoice_zone: null,

  delivery_address_line1: null,
  delivery_address_line2: null,
  delivery_address_city: null,
  delivery_address_state: null,
  delivery_address_pincode: null,
  delivery_address_country: null,
  project_status: "ACTIVE",
  create_ts: "",
  insert_ts: "",
};
