import {
  IEnquiryMockupRequestForm,
  IEnquiryMockupRequestFormProduct,
} from "./enquiry-mrf.types";

export const defaultEnquiryMockupRequestFormProduct: IEnquiryMockupRequestFormProduct =
  {
    product_name: "",
    quantity: 0,
    description: "",
    color_preference: "",
  };

export const defaultEnquiryMRF: IEnquiryMockupRequestForm = {
  mrf_uuid: null,
  enquiry_no: "",
  customer_address_line1: null,
  customer_address_line2: null,
  customer_address_city: null,
  customer_address_state: null,
  customer_address_pincode: null,
  customer_address_country: null,
  delivery_address_line1: null,
  delivery_address_line2: null,
  delivery_address_city: null,
  delivery_address_state: null,
  delivery_address_pincode: null,
  delivery_address_country: null,
  date_of_mockup: null,
  apprx_value_of_the_project: null,
  apprx_budget_for_the_project: null,
  porbablity_of_win: null,
  competitiors: null,
  products_currently_used_by_the_customer: null,
  product_details: [defaultEnquiryMockupRequestFormProduct],
  requested_by_name: null,
  authorised_by_name: null,
  approved_by_name: null,
};
