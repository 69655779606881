import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { IDeviation, IDeviationState } from "./deviation.types";

export const FETCH_DEVIATION_LIST_PROGRESS = "FETCH_DEVIATION_LIST_PROGRESS";
export const FETCH_DEVIATION_LIST_SUCCESS = "FETCH_DEVIATION_LIST_SUCCESS";
export const FETCH_DEVIATION_LIST_FAILED = "FETCH_DEVIATION_LIST_FAILED";

export const fetchDeviationListtProgress = () =>
  action(FETCH_DEVIATION_LIST_PROGRESS);

export const fetchDeviationListtSuccess = (
  data: IDeviationState["list"]["data"],
  totalRecords: number,
) => action(FETCH_DEVIATION_LIST_SUCCESS, { data, totalRecords });
export const fetchDeviationListtFailed = () => action(FETCH_DEVIATION_LIST_FAILED);

export const fetchDeviationListtAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const searchQuery = getSearchQuery(queryParams);
        dispatch(fetchDeviationListtProgress());
        const res = await api.get(`/deviation/get-deviation${searchQuery}`);
        const data: IDeviationState["list"]["data"] = res.data.data;

        const totalRecords = res.data.totalRecords;

        dispatch(fetchDeviationListtSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchDeviationListtFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const FETCH_DEVIATION_PROGRESS = "FETCH_DEVIATION_PROGRESS";
export const FETCH_DEVIATION_SUCCESS = "FETCH_DEVIATION_SUCCESS";
export const FETCH_DEVIATION_FAILED = "FETCH_DEVIATION_FAILED";

export const fetchDeviationProgress = () => action(FETCH_DEVIATION_PROGRESS);
export const fetchDeviationSuccess = (data: IDeviation) =>
  action(FETCH_DEVIATION_SUCCESS, { data });
export const fetchDeviationFailed = (errorMessage: string) =>
  action(FETCH_DEVIATION_FAILED, { errorMessage });

export const fetchDeviationAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchDeviationProgress());
        const res = await api.get(`/deviation/get-deviation?deviation_uuid=${uuid}`);
        const data: IDeviation[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchDeviationSuccess(data[0]));
        } else {
          dispatch(fetchDeviationFailed("Oops! We couldn't find any records."));
        }
      } catch (err: any) {
        dispatch(fetchDeviationFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const upsertDeviationAsync =
  (
    data: IDeviation,
    onCallback: (isSuccess: boolean, deviation?: IDeviation) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const {
          create_ts,
          insert_ts,
          other_requesting_department, //temporary extract
          other_reason,
          ...payload
        } = data;


        dispatch(saveLoaderProgress());
        const res = await api.post("/deviation/upsert-deviation", payload);
        let message = "Deviation saved successfully!";
        onCallback(true, res.data.data);
        dispatch(
          showMessage({
            type: "success",
            displayAs: "snackbar",
            message: message,
          }),
        );
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      } finally {
        dispatch(saveLoaderCompleted());
      }
    };

export const upsertRequestDeviationAsync =
  (
    enquiryNo: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(saveLoaderProgress());
        await api.post("/bom/upsert-request-bom", { enquiry_no: enquiryNo, status: "BOM_REQUESTED" });
        let message = "Deviation requested successfully!";
        onCallback(true);
        dispatch(
          showMessage({
            type: "success",
            displayAs: "snackbar",
            message: message,
          }),
        );
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      } finally {
        dispatch(saveLoaderCompleted());
      }
    };

export const CLEAR_DEVIATION = "CLEAR_DEVIATION";
export const CLEAR_DEVIATION_STATE = "CLEAR_DEVIATION_STATE";
export const clearDeviation = () => action(CLEAR_DEVIATION);
export const clearDeviationState = () => action(CLEAR_DEVIATION_STATE);

