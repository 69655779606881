import produce from "immer";
import { BomActions, CLEAR_BOM, CLEAR_BOM_STATE, FETCH_BOM_FAILED, FETCH_BOM_LIST_FAILED, FETCH_BOM_LIST_PROGRESS, FETCH_BOM_LIST_SUCCESS, FETCH_BOM_PROGRESS, FETCH_BOM_SUCCESS, initialBom, initialBomState } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";

export const bomReducer = (
  state: IStoreState["bom"] = initialBomState,
  action: BomActions,
) => {
  switch (action.type) {
    case FETCH_BOM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_BOM_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_BOM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_BOM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.bom.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_BOM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.bom.loading = LoadState.Loaded;
        draftState.bom.data = data;
      });
      return newState;
    }
    case FETCH_BOM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.bom.loading = LoadState.Failed;
        draftState.bom.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_BOM: {
      const newState = produce(state, (draftState) => {
        draftState.bom.loading = LoadState.NotLoaded;
        draftState.bom.data = initialBom;
        draftState.bom.error = null;
      });
      return newState;
    }

    case CLEAR_BOM_STATE: {
      return initialBomState;
    }

    default: {
      return state;
    }
  }
};
