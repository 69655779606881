import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { uploadFile } from "../../helpers/uploadFile";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { IDrawing, IDrawingState } from "./drawing.types";

export const FETCH_DRAWING_LIST_PROGRESS = "FETCH_DRAWING_LIST_PROGRESS";
export const FETCH_DRAWING_LIST_SUCCESS = "FETCH_DRAWING_LIST_SUCCESS";
export const FETCH_DRAWING_LIST_FAILED = "FETCH_DRAWING_LIST_FAILED";

export const fetchDrawingListProgress = () =>
  action(FETCH_DRAWING_LIST_PROGRESS);

export const fetchDrawingListSuccess = (
  data: IDrawingState["list"]["data"],
  totalRecords: number,
) => action(FETCH_DRAWING_LIST_SUCCESS, { data, totalRecords });
export const fetchDrawingListFailed = () => action(FETCH_DRAWING_LIST_FAILED);

export const fetchDrawingListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const searchQuery = getSearchQuery(queryParams);
        dispatch(fetchDrawingListProgress());
        const res = await api.get(`/bom/get-drawing${searchQuery}`);
        const data: IDrawingState["list"]["data"] = res.data.data;

        const totalRecords = res.data.totalRecords;

        dispatch(fetchDrawingListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchDrawingListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const FETCH_DRAWING_PROGRESS = "FETCH_DRAWING_PROGRESS";
export const FETCH_DRAWING_SUCCESS = "FETCH_DRAWING_SUCCESS";
export const FETCH_DRAWING_FAILED = "FETCH_DRAWING_FAILED";

export const fetchDrawingProgress = () => action(FETCH_DRAWING_PROGRESS);
export const fetchDrawingSuccess = (data: IDrawing) =>
  action(FETCH_DRAWING_SUCCESS, { data });
export const fetchDrawingFailed = (errorMessage: string) =>
  action(FETCH_DRAWING_FAILED, { errorMessage });

export const fetchDrawingAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchDrawingProgress());
        const res = await api.get(`//bom/get-drawing?drawing_uuid=${uuid}`);
        const data: IDrawing[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchDrawingSuccess(data[0]));
        } else {
          dispatch(fetchDrawingFailed("Oops! We couldn't find any records."));
        }
      } catch (err: any) {
        dispatch(fetchDrawingFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const upsertDrawingAsync =
  (
    data: IDrawing,
    file: any,
    onCallback: (isSuccess: boolean, Drawing?: IDrawing) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {


        let path = "";
        if (file) {
          const asPayload = {
            drawing_no: data.drawing_no,
          };
          path = await uploadFile(
            file,
            "Drawing",
            data.drawing_no || "",
            asPayload,
          );
        }
        const {
          create_ts,
          insert_ts,
          ...payload
        } = data;


        dispatch(saveLoaderProgress());
        const res = await api.post("/bom/upsert-drawing", { ...payload, drawing_link: path });
        let message = "Drawing saved successfully!";
        onCallback(true, res.data.data);
        dispatch(
          showMessage({
            type: "success",
            displayAs: "snackbar",
            message: message,
          }),
        );
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      } finally {
        dispatch(saveLoaderCompleted());
      }
    };

export const CLEAR_DRAWING = "CLEAR_DRAWING";
export const CLEAR_DRAWING_STATE = "CLEAR_DRAWING_STATE";
export const clearDrawing = () => action(CLEAR_DRAWING);
export const clearDrawingState = () => action(CLEAR_DRAWING_STATE);

