import produce from "immer";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { initialDrawing, initialDrawingState } from "./defaultState";
import { CLEAR_DRAWING, CLEAR_DRAWING_STATE, DrawingActions, FETCH_DRAWING_FAILED, FETCH_DRAWING_LIST_FAILED, FETCH_DRAWING_LIST_PROGRESS, FETCH_DRAWING_LIST_SUCCESS, FETCH_DRAWING_PROGRESS, FETCH_DRAWING_SUCCESS } from ".";


export const drawingReducer = (
  state: IStoreState["drawing"] = initialDrawingState,
  action: DrawingActions,
) => {
  switch (action.type) {
    case FETCH_DRAWING_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_DRAWING_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_DRAWING_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_DRAWING_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.drawing.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_DRAWING_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.drawing.loading = LoadState.Loaded;
        draftState.drawing.data = data;
      });
      return newState;
    }
    case FETCH_DRAWING_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.drawing.loading = LoadState.Failed;
        draftState.drawing.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_DRAWING: {
      const newState = produce(state, (draftState) => {
        draftState.drawing.loading = LoadState.NotLoaded;
        draftState.drawing.data = initialDrawing;
        draftState.drawing.error = null;
      });
      return newState;
    }

    case CLEAR_DRAWING_STATE: {
      return initialDrawingState;
    }

    default: {
      return state;
    }
  }
};
