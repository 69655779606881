import { LoadState } from "../../../constants/enums";
import { defaultEnquiry } from "../../enquiries/enquiry.state";
import {
  IOrderProcessing,
  IOrderProcessingState,
  IUpsertOrderProcessing,
} from "./orderProcessing.types";

export const defaultOrderProcessing: IOrderProcessing = {
  ...defaultEnquiry,
};

export const defaultUpsertOrderProcessing: IUpsertOrderProcessing = {
  enquiry_uuid: null,
  factory_time_frame_of_closing: null,
  remarks: null,
  status: "ORDER",
};

export const defaultOrderProcessingState: IOrderProcessingState = {
  salesOrders: {
    loading: LoadState.NotLoaded,
    data: [],
    totalRecords: 0,
    error: null,
  },
};
