import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IWorkOrderState } from "./workOrder.types";

// ###########################################################################
// ##################### Fetch Multiple Work Order Jobs ######################
// ###########################################################################
export const FETCH_WORK_ORDER_JOB_LIST_PROGRESS =
  "FETCH_WORK_ORDER_JOB_LIST_PROGRESS";
export const FETCH_WORK_ORDER_JOB_LIST_SUCCESS =
  "FETCH_WORK_ORDER_JOB_LIST_SUCCESS";
export const FETCH_WORK_ORDER_JOB_LIST_FAILED =
  "FETCH_WORK_ORDER_JOB_LIST_FAILED";

export const fetchWorkOrderJobListProgress = () =>
  action(FETCH_WORK_ORDER_JOB_LIST_PROGRESS);

export const fetchWorkOrderJobListSuccess = (
  data: IWorkOrderState["workOrderJobList"]["data"],
  totalRecords: number,
) => action(FETCH_WORK_ORDER_JOB_LIST_SUCCESS, { data, totalRecords });
export const fetchWorkOrderJobListFailed = () =>
  action(FETCH_WORK_ORDER_JOB_LIST_FAILED);

export const fetchWorkOrderJobListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchWorkOrderJobListProgress());
      const res = await api.get(`/work_order/get-work-order-job${searchQuery}`);
      const data: IWorkOrderState["workOrderJobList"]["data"] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchWorkOrderJobListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchWorkOrderJobListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ###########################################################################
// ###################### Fetch Single Work Order Job ########################
// ###########################################################################
export const FETCH_SINGLE_WORK_ORDER_JOB_PROGRESS =
  "FETCH_SINGLE_WORK_ORDER_JOB_PROGRESS";
export const FETCH_SINGLE_WORK_ORDER_JOB_SUCCESS =
  "FETCH_SINGLE_WORK_ORDER_JOB_SUCCESS";
export const FETCH_SINGLE_WORK_ORDER_JOB_FAILED =
  "FETCH_SINGLE_WORK_ORDER_JOB_FAILED";

export const fetchSingleWorkOrderJobProgress = () =>
  action(FETCH_SINGLE_WORK_ORDER_JOB_PROGRESS);
export const fetchSingleWorkOrderJobSuccess = (
  data: IWorkOrderState["singleWorkOrderJob"]["data"],
) => action(FETCH_SINGLE_WORK_ORDER_JOB_SUCCESS, { data });
export const fetchSingleWorkOrderJobFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_WORK_ORDER_JOB_FAILED, { errorMessage });

export const fetchSingleWorkOrderJobAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleWorkOrderJobProgress());
      const res = await api.get(
        `/work_order/get-work-order-job?work_order_job_uuid=${uuid}`,
      );
      const data: IWorkOrderState["singleWorkOrderJob"]["data"][] =
        res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleWorkOrderJobSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleWorkOrderJobFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleWorkOrderJobFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ###########################################################################
// ##################### Upsert Single Work Order Jog ########################
// ###########################################################################
export const upsertSingleWorkOrderJobAsync =
  (
    data: IWorkOrderState["singleWorkOrderJob"]["data"],
    onCallback: (
      isSuccess: boolean,
      workOrderJob?: IWorkOrderState["singleWorkOrderJob"]["data"],
    ) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post("/work_order/upsert-work-order-job", payload);
      let message = "Work Order Job saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ###########################################################################
// ######################## Clear State #########################
// ###########################################################################
export const CLEAR_SINGLE_WORK_ORDER_JOB = "CLEAR_SINGLE_WORK_ORDER_JOB";
export const CLEAR_WORK_ORDER_JOB_LIST = "CLEAR_WORK_ORDER_JOB_LIST";
export const clearSingleWorkOrderJob = () =>
  action(CLEAR_SINGLE_WORK_ORDER_JOB);
export const clearWorkOrderJobList = () => action(CLEAR_WORK_ORDER_JOB_LIST);
