import { LoadState } from "../../constants/enums";
import { INcp, INcpState } from "./ncp.types";

export const defaultNcp: INcp = {
  ncp_uuid: null,
  doc_no: null,
  rev_no: null,
  ncp_no: null,
  sales_order_no: null,
  customer_uuid: null,
  customer_name: null,
  incoming_oba: null,
  invoice_no: null,
  part_name: null,
  part_code: null,
  work_center: null,
  work_order_no: null,
  work_order_date: null,
  drawing_no: null,
  details_of_ncp: null,
  source_of_problem: null,
  problem_status: null,
  action_on_defective_lot: null,
  MOBU_head_approval: null,
  VP_approved_by: null,
  short_term_action: null,
  root_cause: null,
  corrective_action: null,
  verification_of_ca: null,
  document_control: null,
  issued_by_uuid: null,
  issued_by_name: null,
  approved_by_uuid: null,
  approved_by_name: null,
  status: "ACTIVE",
};

export const defaultNcpState: INcpState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  ncp: {
    data: defaultNcp,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
