import produce from "immer";

import { CLEAR_NCP, CLEAR_NCP_STATE, FETCH_NCP_FAILED, FETCH_NCP_LIST_FAILED, FETCH_NCP_LIST_PROGRESS, FETCH_NCP_LIST_SUCCESS, FETCH_NCP_PROGRESS, FETCH_NCP_SUCCESS, NcpActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultNcp, defaultNcpState } from "./defaultState";

export const ncpReducer = (
  state: IStoreState["ncp"] = defaultNcpState,
  action: NcpActions,
) => {
  switch (action.type) {
    case FETCH_NCP_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_NCP_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_NCP_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_NCP_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.ncp.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_NCP_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.ncp.loading = LoadState.Loaded;
        draftState.ncp.data = data;
      });
      return newState;
    }
    case FETCH_NCP_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.ncp.loading = LoadState.Failed;
        draftState.ncp.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_NCP: {
      const newState = produce(state, (draftState) => {
        draftState.ncp.loading = LoadState.NotLoaded;
        draftState.ncp.data = defaultNcp;
        draftState.ncp.error = null;
      });
      return newState;
    }

    case CLEAR_NCP_STATE: {
      return defaultNcpState;
    }

    default: {
      return state;
    }
  }
};
