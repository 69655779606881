import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IQueryParams } from "../../common/common.types";
import { IProductAddOn } from "./product-addon.types";
import { getSearchQuery } from "../../common/helpers";

export const FETCH_PRODUCT_ADD_ON_LIST_PROGRESS =
  "FETCH_PRODUCT_ADD_ON_LIST_PROGRESS";
export const FETCH_PRODUCT_ADD_ON_LIST_SUCCESS =
  "FETCH_PRODUCT_ADD_ON_LIST_SUCCESS";
export const FETCH_PRODUCT_ADD_ON_LIST_FAILED =
  "FETCH_PRODUCT_ADD_ON_LIST_FAILED";

export const fetchProductAddOnListProgress = () =>
  action(FETCH_PRODUCT_ADD_ON_LIST_PROGRESS);
export const fetchProductAddOnListSuccess = (
  data: IProductAddOn[],
  totalRecords: number,
) => action(FETCH_PRODUCT_ADD_ON_LIST_SUCCESS, { data, totalRecords });
export const fetchProductAddOnListFailed = () =>
  action(FETCH_PRODUCT_ADD_ON_LIST_FAILED);

export const fetchProductAddOnListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchProductAddOnListProgress());
      const res = await api.get(`/quotes/get-product-add-on${searchQuery}`);

      const data: IProductAddOn[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchProductAddOnListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchProductAddOnListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchAddOnsForSingleProductAsync =
  (product_uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchProductAddOnListProgress());
      const res = await api.get(
        `/quotes/get-product-add-on?product_uuid=${product_uuid}`,
      );

      const data: IProductAddOn[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchProductAddOnListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchProductAddOnListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_SINGLE_PRODUCT_ADD_ON_PROGRESS =
  "FETCH_SINGLE_PRODUCT_ADD_ON_PROGRESS";
export const FETCH_SINGLE_PRODUCT_ADD_ON_SUCCESS =
  "FETCH_SINGLE_PRODUCT_ADD_ON_SUCCESS";
export const FETCH_SINGLE_PRODUCT_ADD_ON_FAILED =
  "FETCH_SINGLE_PRODUCT_ADD_ON_FAILED";

export const fetchSingleProductAddOnProgress = () =>
  action(FETCH_SINGLE_PRODUCT_ADD_ON_PROGRESS);
export const fetchSingleProductAddOnSuccess = (data: IProductAddOn) =>
  action(FETCH_SINGLE_PRODUCT_ADD_ON_SUCCESS, { data });
export const fetchSingleProductAddOnFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_PRODUCT_ADD_ON_FAILED, { errorMessage });

export const fetchSingleProductAddOnAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleProductAddOnProgress());
      const res = await api.get(
        `/quotes/get-product-add-on?product_add_on_uuid=${uuid}`,
      );
      const data: IProductAddOn[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleProductAddOnSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleProductAddOnFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleProductAddOnFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleProductsAddOnAsync =
  (
    template: IProductAddOn,
    onCallback: (isSuccess: boolean, template?: IProductAddOn) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const { create_ts, insert_ts, ...payload } = template;

      const res = await api.post("/quotes/upsert-product-add-on", payload);
      dispatch(
        showMessage({
          type: "success",
          message: "Product Addon saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_PRODUCT_ADD_ON = "CLEAR_SINGLE_PRODUCT_ADD_ON";
export const CLEAR_PRODUCT_ADD_ON_LIST = "CLEAR_PRODUCT_ADD_ON_LIST";
export const clearSingleProductAddOnAsync = () =>
  action(CLEAR_SINGLE_PRODUCT_ADD_ON);
export const clearProductAddOnListAsync = () =>
  action(CLEAR_PRODUCT_ADD_ON_LIST);
