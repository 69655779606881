import { action } from "typesafe-actions";
import { IEnquiryDocument } from "./enquiry-documents.types";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { api } from "../../../api/api";
import { uploadFile } from "../../../helpers/uploadFile";
import { IStoreState } from "../../initialStoreState";
import {
  showMessage,
  saveLoaderProgress,
  saveLoaderCompleted,
} from "../../messages/messagesActions";

export const FETCH_ENQUIRY_DOCUMENTS_LIST_PROGRESS =
  "FETCH_ENQUIRY_DOCUMENTS_LIST_PROGRESS";
export const FETCH_ENQUIRY_DOCUMENTS_LIST_SUCCESS =
  "FETCH_ENQUIRY_DOCUMENTS_LIST_SUCCESS";
export const FETCH_ENQUIRY_DOCUMENTS_LIST_FAILED =
  "FETCH_ENQUIRY_DOCUMENTS_LIST_FAILED";

export const fetchEnquiryDocumentsListProgress = () =>
  action(FETCH_ENQUIRY_DOCUMENTS_LIST_PROGRESS);
export const fetchEnquiryDocumentsListSuccess = (data: IEnquiryDocument[]) =>
  action(FETCH_ENQUIRY_DOCUMENTS_LIST_SUCCESS, { data });
export const fetchEnquiryDocumentsListFailed = () =>
  action(FETCH_ENQUIRY_DOCUMENTS_LIST_FAILED);

export const fetchEnquiryDocumentsListAsync =
  (
    enquiry_no: string,
    page: number,
    rowsPerPage: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEnquiryDocumentsListProgress());
      const res = await api.get(
        `/enquiry/get-enquiry-attachment?enquiry_no=${enquiry_no}&pageNo=${page}&itemPerPage=${rowsPerPage}`,
      );
      const data: IEnquiryDocument[] = res.data.data;
      dispatch(fetchEnquiryDocumentsListSuccess(data));
    } catch (err: any) {
      dispatch(fetchEnquiryDocumentsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const addEnquiryDocumentsAsync =
  (
    data: IEnquiryDocument,
    file: any | null,
    onCallback: (isSuccess: boolean, path?: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { incremental_no, create_ts, insert_ts, rowId, ...rest } = data;
      dispatch(saveLoaderProgress());
      const asPayload = {
        enquiry_no: data.enquiry_no,
        document_type: data.document_type,
      };
      const path = await uploadFile(
        file,
        "ENQUIRY",
        data.file_url || "",
        asPayload,
      );
      await api.post("/enquiry/upsert-enquiry-attachment", {
        ...rest,
        file_url: path,
      });

      dispatch(saveLoaderCompleted());
      dispatch(
        showMessage({
          type: "success",
          message: "Document saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, path);
    } catch (err: any) {
      onCallback(false, "");
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
