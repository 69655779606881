import React from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  FormControl,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageContainer } from "../../../components/container/PageContainer";

import {
  CustomFormLabel,
  CustomTextField,
  CustomTypography,
} from "../../../components/formsComponents";
import { LogoIcon } from "../../../layout/fullLayout/logo/Logo";
import { useAuth } from "../../../contexts/AuthProvider/AuthProvider";
import { useFormik } from "formik";
import { CustomAlert } from "../../../components/formsComponents/CustomAlert";
import { rolesDashboards } from "../../../router/rolesDashboard";
import Paper from "@mui/material/Paper/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import * as Yup from "yup";
import { showMessage } from "../../../redux/messages/messagesActions";
import { useDispatchWrapper } from "../../../hooks";
import { SendOtpOnMail } from "./SendOtpOnMail";
import { ValidateOtpAndResetPassword } from "./ValidateOtpAndResetPassword";

export const ForgetPassword: React.FC = () => {
  const [step, setStep] = React.useState<number>(0);
  const [userEmail, setUserEmail] = React.useState<string>("");

  return (
    <PageContainer title="Forget Password" description="this is Login page">
      <Grid
        container
        spacing={0}
        sx={{
          height: "100vh",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Grid
          container
          sx={{
            position: "relative",
            top: "10%",
          }}
        >
          <Grid item xs={1} md={4}></Grid>
          <Grid item xs={10} md={4}>
            <Paper
              sx={{
                p: 4,
                pt: 5,
                pb: 5,
                border: "none rgb(230, 235, 241)",
                borderRadius: "4px",
                boxShadow: "rgb(0 0 0 / 8%) 0px 1px 4px",
              }}
            >
              <Box
                sx={{
                  top: "-20px",
                  position: "relative",
                }}
              >
                <LogoIcon />
              </Box>
              <Typography variant="h2" fontWeight={"bold"}>
                {step === 0 ? "Forget Password" : "Reset Password"}
              </Typography>
              {step === 0 && (
                <SendOtpOnMail
                  onSuccess={(email) => {
                    setStep(1);
                    setUserEmail(email);
                  }}
                />
              )}
              {step === 1 && <ValidateOtpAndResetPassword email={userEmail} />}
            </Paper>
          </Grid>
          <Grid item xs={1} md={4} />
        </Grid>

        <Grid container spacing={0} sx={{ pb: 3 }}>
          <Grid item xs={12} md={4}>
            <CustomTypography
              variant="body1"
              textAlign={"center"}
              sx={{ color: "rgb(140, 140, 140)" }}
            >
              © 2023 Rockworth Public Company Limited. All rights reserved.
            </CustomTypography>
          </Grid>
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={3}>
            <CustomTypography
              variant="body1"
              textAlign={"center"}
              sx={{ color: "rgb(140, 140, 140)" }}
            >
              Email: sales@rockworth.com
            </CustomTypography>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTypography
              variant="body1"
              textAlign={"center"}
              sx={{ color: "rgb(140, 140, 140)" }}
            >
              24/7 EMERGENCY SERVICE: +91 8297223389
            </CustomTypography>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};
