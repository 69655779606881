import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../../constants/enums";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { WORK_ORDER_ROUTE } from "./WorkOrder.constants";

const WorkOrderTable = Loadable(
  lazy(() =>
    import("./WorkOrderTable").then(({ WorkOrderTable }) => ({
      default: WorkOrderTable,
    })),
  ),
);
const ManageSingleWorkOrder = Loadable(
  lazy(() =>
    import("./ManageSingleWorkOrder").then(({ ManageSingleWorkOrder }) => ({
      default: ManageSingleWorkOrder,
    })),
  ),
);

export const workOrderRoutes = [
  {
    path: WORK_ORDER_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <WorkOrderTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${WORK_ORDER_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <ManageSingleWorkOrder />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${WORK_ORDER_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
        <ManageSingleWorkOrder />
      </AuthorizedRoute>
    ),
  },
];
