import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../../api/api";
import { IStoreState } from "../../initialStoreState";
import { showMessage } from "../../messages/messagesActions";
import { IEnquiryORF } from "./enquiry-orf.types";
import { getSearchQuery } from "../../common/helpers";
import { IQueryParams } from "../../common/common.types";

export const FETCH_ORF_LIST_PROGRESS = "FETCH_ORF_LIST_PROGRESS";
export const FETCH_ORF_LIST_SUCCESS = "FETCH_ORF_LIST_SUCCESS";
export const FETCH_ORF_LIST_FAILED = "FETCH_ORF_LIST_FAILED";

export const fetchEnquiryOrfListProgress = () =>
  action(FETCH_ORF_LIST_PROGRESS);
export const fetchEnquiryOrfListSuccess = (
  data: IEnquiryORF[],
  totalRecords: number,
) => action(FETCH_ORF_LIST_SUCCESS, { data, totalRecords });
export const fetchEnquiryOrfListFailed = (errorMessage: string) =>
  action(FETCH_ORF_LIST_FAILED, {
    errorMessage,
  });

export const fetchEnquiryOrfListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);

      dispatch(fetchEnquiryOrfListProgress());
      const res = await api.get(
        `/approval/get-approval${searchQuery}&table_name=latest_orf`,
      );
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchEnquiryOrfListSuccess(data, data.totalRecords));
      } else {
        dispatch(
          fetchEnquiryOrfListFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchEnquiryOrfListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_ORF_PROGRESS = "FETCH_ORF_PROGRESS";
export const FETCH_ORF_SUCCESS = "FETCH_ORF_SUCCESS";
export const FETCH_ORF_FAILED = "FETCH_ORF_FAILED";

export const fetchOrfProgress = () => action(FETCH_ORF_PROGRESS);
export const fetchOrfSuccess = (data: IEnquiryORF) =>
  action(FETCH_ORF_SUCCESS, { data });
export const fetchOrfFailed = (errorMessage: string) =>
  action(FETCH_ORF_FAILED, {
    errorMessage,
  });

export const fetchOrfAsync =
  (enquiryNo: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchOrfProgress());

      const res = await api.get(`/enquiry/get-orf?enquiry_no=${enquiryNo}`);
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchOrfSuccess(data[0]));
      } else {
        dispatch(
          fetchOrfFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchOrfFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertOrfAsync =
  (
    data: IEnquiryORF,
    onCallback: (isSuccess: boolean, data?: IEnquiryORF) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        enquiry_uuid,
        approval_uuid,
        approved_by_name,
        approved_by_uuid,
        requested_by_uuid,
        is_user_approver,
        create_ts,
        insert_ts,
        status,
        ...payload
      } = data;
      const res = await api.post("/enquiry/upsert-orf", payload);
      dispatch(
        showMessage({
          type: "success",
          message: "ORF Data saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_ORF_LIST = "CLEAR_ORF_LIST";
export const CLEAR_SINGLE_ORF = "CLEAR_SINGLE_ORF";
export const clearSingleOrfList = () => action(CLEAR_ORF_LIST);
export const clearSingleOrf = () => action(CLEAR_SINGLE_ORF);
