import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../../api/api";
import { IDate } from "../../../components/Table/hooks/useDateFilter";
import { IStoreState } from "../../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IApproval, IPostEnquiryOrderApproval, IUpsertApproval } from "./approvals.types";

export const FETCH_APPROVAL_LIST_PROGRESS = "FETCH_APPROVAL_LIST_PROGRESS";
export const FETCH_APPROVAL_LIST_SUCCESS = "FETCH_APPROVAL_LIST_SUCCESS";
export const FETCH_APPROVAL_LIST_FAILED = "FETCH_APPROVAL_LIST_FAILED";

export const fetchApprovalListProgress = () =>
  action(FETCH_APPROVAL_LIST_PROGRESS);
export const fetchApprovalQuotesListSuccess = (
  list: IApproval[],
  totalRecords: number,
) => action(FETCH_APPROVAL_LIST_SUCCESS, { list, totalRecords });
export const fetchApprovalQuotesListFailed = () =>
  action(FETCH_APPROVAL_LIST_FAILED);

export const fetchApprovalListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchApprovalListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `/approval/get-approval?table_name=latest_order&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && searchValue) {
        finalUrl = `/approval/get-approval?table_name=latest_order&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&columns=${[
          searchType,
        ]}&value=${searchValue}`;
      }

      const res = await api.get(finalUrl);
      const data: IApproval[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchApprovalQuotesListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchApprovalQuotesListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const handleEnquiryOrderApprovalAsync =
  (
    data: IPostEnquiryOrderApproval,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/enquiry/approve-order", data);
      let message = "Action success!";
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(true);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

  export const upsertApprovalRequest =
  (
    data: IUpsertApproval,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const { create_ts, insert_ts, ...rest } = data;
    try {
      dispatch(saveLoaderProgress());
      await api.post("/approval/insert-approval", rest);
      let message = "Approval raised successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_APPROVALS_STATE = "CLEAR_APPROVALS_STATE";
export const clearApprovalsState = () => action(CLEAR_APPROVALS_STATE);
