import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { IEnquiryQRF } from "./enquiry-qrf.types";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";

export const FETCH_SINGLE_ENQUIRY_QRF_PROGRESS =
  "FETCH_SINGLE_ENQUIRY_QRF_PROGRESS";
export const FETCH_SINGLE_ENQUIRY_QRF_SUCCESS =
  "FETCH_SINGLE_ENQUIRY_QRF_SUCCESS";
export const FETCH_SINGLE_ENQUIRY_QRF_FAILED =
  "FETCH_SINGLE_ENQUIRY_QRF_FAILED";

export const fetchSingleEnquiryQrfProgress = () =>
  action(FETCH_SINGLE_ENQUIRY_QRF_PROGRESS);
export const fetchSingleEnquiryQrfSuccess = (data: IEnquiryQRF) =>
  action(FETCH_SINGLE_ENQUIRY_QRF_SUCCESS, { data });
export const fetchSingleEnquiryQrfFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_ENQUIRY_QRF_FAILED, { errorMessage });

export const fetchSingleEnquiryQrfAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleEnquiryQrfProgress());
      const res = await api.get(`/enquiry/get-qrf?qrf_uuid=${uuid}`);
      const data: IEnquiryQRF[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleEnquiryQrfSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleEnquiryQrfFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleEnquiryQrfFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchSingleEnquiryQrfByEnquiryNumberAsync =
  (enquiry_no: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleEnquiryQrfProgress());
      const res = await api.get(`/enquiry/get-qrf?enquiry_no=${enquiry_no}`);
      const data: IEnquiryQRF[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleEnquiryQrfSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleEnquiryQrfFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleEnquiryQrfFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertEnquiryQRFAsync =
  (
    qrf_data: IEnquiryQRF,
    onCallback: (isSuccess: boolean, orf_info?: IEnquiryQRF) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = qrf_data;
      dispatch(saveLoaderProgress());
      const res = await api.post("/enquiry/upsert-qrf", payload);
      let message = "QRF saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_ENQUIRY_QRF = "CLEAR_SINGLE_ENQUIRY_QRF";
export const clearSingleEnquiryQrf = () => action(CLEAR_SINGLE_ENQUIRY_QRF);
