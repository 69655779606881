import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { showMessage } from "../../messages/messagesActions";
import { IEnquiryHistory } from "./enquiry-history.types";

// --------------- Enquiry History Actions----------------------

export const FETCH_ENQUIRY_HISTORY_LIST_PROGRESS =
  "FETCH_ENQUIRY_HISTORY_LIST_PROGRESS";
export const FETCH_ENQUIRY_HISTORY_LIST_SUCCESS =
  "FETCH_ENQUIRY_HISTORY_LIST_SUCCESS";
export const FETCH_ENQUIRY_HISTORY_LIST_FAILED =
  "FETCH_ENQUIRY_HISTORY_LIST_FAILED";

export const fetchEnquiryHistoryListProgress = () =>
  action(FETCH_ENQUIRY_HISTORY_LIST_PROGRESS);
export const fetchEnquiryHistoryListSuccess = (
  list: IEnquiryHistory[],
  totalRecords: number,
) => action(FETCH_ENQUIRY_HISTORY_LIST_SUCCESS, { list, totalRecords });
export const fetchEnquiryHistoryListFailed = (errorMessage: string) =>
  action(FETCH_ENQUIRY_HISTORY_LIST_FAILED, { errorMessage });

export const fetchEnquiryHistoryListAsync =
  (
    leadId: string,
    pageNumber: number,
    rowsInPerPage: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEnquiryHistoryListProgress());
      const res = await api.get(
        `/history/get-history?module_uuid=${leadId}&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`,
      );
      // const res = await api.get(`/history/get-history?module_name=Lead&pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`);
      const data: IEnquiryHistory[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchEnquiryHistoryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchEnquiryHistoryListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleEnquiryHistoryAsync =
  (
    lead: IEnquiryHistory,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { history_uuid, create_ts, ...rest } = lead;

      await api.post("/leads/upsert-leads", rest);
      let message = "Enquiry History saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_ENQUIRY_HISTORY_LIST_STATE =
  "CLEAR_ENQUIRY_HISTORY_LIST_STATE";
export const CLEAR_ENQUIRY_HISTORY_LIST = "CLEAR_ENQUIRY_HISTORY_LIST";

export const clearEnquiryHistoryState = () =>
  action(CLEAR_ENQUIRY_HISTORY_LIST_STATE);
export const clearEnquiryHistoryList = () => action(CLEAR_ENQUIRY_HISTORY_LIST);
