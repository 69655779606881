import React from "react";
import { styled } from "@mui/material/styles";
import {
  Checkbox,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from "@mui/material";
import { ISelectOption } from "../../constants/types";
import { CustomFormLabel } from "./CustomFormLabel";

export const CustomSelect = styled((props: SelectProps) => (
  <Select {...props} />
))(({ theme }) => ({
  "& .MuiSelect-select": {
    color: "#767e89",
    padding: "9px 13px",
    fontSize: "0.87rem",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "5px",
    borderColor: `${
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "#dee3e9"
    }`,
  },
  "& .MuiSelect-select::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
}));

interface ICustomSelectProps extends SelectProps {
  options: { label: number | string | null; value: string | number }[];
  helperText?: string;
  readOnlyMode?: boolean;
  optionalData?: ISelectOption[];
}

export const ControlledCustomSelect: React.FC<ICustomSelectProps> = (props) => {
  const { readOnlyMode = false } = props;
  const renderPlaceholder = () => props.placeholder;
  if (readOnlyMode) {
    return (
      <Typography variant="body1" padding={1} fontSize={"0.8rem"}>
        <>{props.value || "--"}</>
      </Typography>
    );
  }
  return (
    <>
      <CustomSelect
        {...props}
        className={props.name}
        value={props.value || ""}
        renderValue={(selected) =>
          selected
            ? getLabelFromValue(selected, props.options, props.optionalData)
            : renderPlaceholder()
        }
      >
        {props.options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {props.multiple && Array.isArray(props.value) && (
                <Checkbox checked={props.value.indexOf(option.value) > -1} />
              )}
              {option.label}
            </MenuItem>
          );
        })}
      </CustomSelect>
      {props.error && (
        <Typography
          variant="body2"
          sx={{
            fontSize: "0.75rem",
            color: "#e46a76",
            marginLeft: 2,
            marginTop: 0.75,
          }}
        >
          {props.helperText}
        </Typography>
      )}
    </>
  );
};

const getLabelFromValue = (
  value: any,
  options: ICustomSelectProps["options"],
  optionalData?: ICustomSelectProps["optionalData"],
) => {
  if (Array.isArray(value)) {
    return value;
  }
  const option = options
    // .concat(optionalData ? optionalData : [])
    .find((option) => option.value === value);
  return option ? option.label : value;
};

interface ICustomSelectorProps extends Omit<SelectProps, "error"> {
  label?: string;
  options: string[] | number[];
  error?: string;
  readOnlyMode?: boolean;
}

export const CustomSelectorWithLabel: React.FC<ICustomSelectorProps> = (
  props,
) => {
  const { error, label, readOnlyMode, ...restProps } = props;
  return (
    <>
      {label && <CustomFormLabel>{label}</CustomFormLabel>}
      {props.readOnlyMode ? (
        <Typography variant="body1" padding={1} fontSize={"0.8rem"}>
          <>{props.value || "--"}</>
        </Typography>
      ) : (
        <CustomSelect
          {...restProps}
          className={props.name}
          value={props.value}
          placeholder={props.placeholder}
        >
          {props.options.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {/* {props.multiple && Array.isArray(props.value) && (
                <Checkbox checked={props.options.indexOf(option) > -1} />
              )} */}
                {option}
              </MenuItem>
            );
          })}
        </CustomSelect>
      )}
      {error && (
        <Typography
          variant="body2"
          sx={{
            fontSize: "0.75rem",
            color: "#e46a76",
            marginLeft: 2,
            marginTop: 0.75,
          }}
        >
          {error}
        </Typography>
      )}
    </>
  );
};
