import produce from "immer";
import { IStoreState } from "../../initialStoreState";

import { ApprovalItemActions, CLEAR_APPROVAL_ITEM_STATE, FETCH_APPROVAL_ITEM_LIST_PROGRESS, FETCH_APPROVAL_ITEM_LIST_SUCCESS } from ".";
import { LoadState } from "../../../constants/enums";
import { initialApprovalItemState } from "./defaultState";

export const approvalItemReducer = (
  state: IStoreState["approvalItem"] = initialApprovalItemState,
  action: ApprovalItemActions,
) => {
  switch (action.type) {
    case FETCH_APPROVAL_ITEM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_APPROVAL_ITEM_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }

    case CLEAR_APPROVAL_ITEM_STATE: {
      return initialApprovalItemState;
    }
    default: {
      return state;
    }
  }
};
