export enum LoadState {
  NotLoaded = 0,
  InProgress = 1,
  Loaded = 2,
  Failed = 3,
}

export enum SaveState {
  NotStarted = 0,
  Progress = 1,
  Saved = 2,
}

export enum USER_ROLES {
  ADMIN = 1,
  CSR = 2,
  PRODUCER = 3,
  MARKETER = 4,
  BRANCH_MANAGER = 5,
}

export enum TASK_SUBTYPE {
  VEHICLE_CHANGE = "Vehicle Change",
  DRIVER_CHANGE = "Driver Change",
  COVERAGE_CHANGE = "Coverage Change",
  GARAGE_PLATE_CHANGE = "Garage Plate Change",
  INTERESTED_PARTY_CHANGE = "Interested Party Change",
  LESSOR_CHANGE = "Lessor Change",
  ADDRESS_CHANGE = "Address Change",
  RISK_LOCATION_CHANGE = "Risk Location Change",
  CANCELLATION_REINSTALLMENT = "Cancellation/Reinstatement",
  BUSINESS_SUBMISSION = "Business Submission",
  GENERAL_CHANGE = "General Change",
  PAYMENT_AUTH_CHANGE = "Payment Authorization Change",
  LAO_BOR = "LAO/BOR",
  NEW_BUSINESS = "Business",
  NEW_PERSONAL_AUTO = "Personal Auto",
  NEW_HOME = "Home",
  NEW_COMMERCIAL_AUTO = "Commercial Auto",
  REMARKET_COMMERCIAL_AUTO = "Commercial Auto",
  REMARKET_HOME = "Home",
  REMARKET_BUSINESS = "Business",
  REMARKET_PERSONAL_AUTO = "Personal Auto",
}

export enum POLICIES {
  BUSINESS_POLICY = "Business Policy",
  AUTOMOBILE_POLICY = "Automobile Policy",
  HABITATIONAL_POLICY = "Habitational Policy",
  COMMERCIAL_AUTO_POLICY = "Commercial Auto Policy",
  LIFE_AND_FINCANCIAL_POLICY = "Life & Fincancial Policy",
}

export enum POLICIES_NAMES {
  BUSINESS_POLICY = "Business",
  AUTOMOBILE_POLICY = "Automobile",
  HABITATIONAL_POLICY = "Habitational",
  COMMERCIAL_AUTO_POLICY = "Commercial Auto",
  LIFE_AND_FINCANCIAL_POLICY = "Life & Fincancial",
}

export enum ERROR_MESSAGES {
  "SERVER_ERROR" = "Someting went to be wrong!",
  "POLICY_NOT_FOUND" = "Oops! We couldn't find any records at the moment. Please ensure that the provided information is accurate and try again.",
}

export enum MODULE_IDS {
  LEADS = "ENQUIRY|ENQUIRY|LATEST_ENQUIRY_WITH_PROJECT",
  PRODUCTS = "PRODUCTS|PRODUCTS|LATEST_PRODUCT",
  QUOTES = "QUOTES|QUOTES|LATEST_QUOTES",
  PERFORMA = "QUOTES|PROFORMA INVOICE|LATEST_PROFORMA_INVOICE",
  ORDER_PROCCESSING = "",
  ORDER = "ENQUIRY|ORDERS|LATEST_ORDER",
  ORDER_REQUESTED = "APPROVAL|ORDER PROCESSING|LATEST_APPROVAL",
  RENDERINGS_JOBS = "RENDERINGS|JOBS|LATEST_RENDERING",
  RENDERINGS_PARTNERS = "RENDERINGS|PARTNERS|LATEST_RENDERING_PARTNERS",

  CUSTOMERS = "ENTITIES|CUSTOMERS|LATEST_CUSTOMER",
  CONTACTS = "ENTITIES|CONTACTS|LATEST_CONTACTS",
  TASKBOARD = "TASKS|TASKBOARD|LATEST_TASKS",
  USERS = "USERS|USERS|LATEST_USER",
  ROLES = "latest_roles",
  SECURITY = "SECURITY|SECURITY|ROLE_MODULE",
  COSTING_SHEET = "COSTING SHEET|COSTING SHEET|LATEST_COSTING_SHEET",
  APPROVAL_QUOTE = "APPROVAL|QUOTES|LATEST_APPROVAL",
  APPROVAL_ITEM = "APPROVAL|PRODUCT|LATEST_APPROVAL",
  APPROVAL_COSTINGSHEET = "APPROVAL|COSTING SHEET|LATEST_APPROVAL",
  APPROVAL_ORF = "APPROVAL|ORF|LATEST_APPROVAL",
  APPROVALS_LIST = "APPROVAL|ORDER PROCESSING|LATEST_APPROVAL",
  BOM = "BOM|BOM|LATEST_BOM",
  DRAWING = "DRAWING|DRAWING|LATEST_DRAWING",
  ECN = "ECN|ECN|LATEST_ECN",
  NCP = "NCP|NCP|LATEST_NCP",
  // APPROVAL = "approval"
}
