import { LoadState } from "../../constants/enums";
import { IEcn, IEcnState } from "./ecn.types";

export const defaultEcn: IEcn = {
  ecn_uuid: null,
  ecn_no: "",
  drawing_no: "",
  distribute_to: "",
  topic: null,
  project: null,
  code: null,
  part_name: null,
  add_update_delete: "ADD",
  other: null,
  problem: null,
  counter_measure: null,
  detail: null,
  effective_time: null,
  stock_condition: null,
  document_control: null,
  issued_by_uuid: null,
  issued_by_name: null,
  approved_by_uuid: null,
  approved_by_name: null,
  status: "ACTIVE",
};

export const defaultEcnState: IEcnState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  ecn: {
    data: defaultEcn,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
